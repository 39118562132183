import React from 'react';
import moment from 'moment';
import { Trans } from 'react-i18next';
import i18n from '@launchpad/i18n';
import {
  FormSubmitWrapper,
  InputField,
  FormHandler,
  AppButton
} from '../../../index';
import AppDatePicker from '../../../components/elements/form/date/AppDatePicker';
import FxCalculatorModel from '../models/FxCalculatorModel';
import AppSelect from '../../../components/elements/form/AppSelect';
import CurrencyModel from '../models/CurrencyModel';
import AppRadioInput from '../../../components/elements/form/AppRadioInput';
import NumberHelper from '../../../util/NumberHelper';

const SectionTitle = props => {
  return (
    <div className="exchange-fee-title">
      <h5>{props.children}</h5>
    </div>
  );
};

export default class CalculatorComponent extends React.Component<any, any> {
  formHandler: FormHandler;

  initialState: any;

  constructor(props: any) {
    super(props);

    this.initialState = {
      transactionDate: moment().format('YYYY-MM-DD'),
      transactionType: 'nonEuroPrepaidCardPurchases',
      transactionCurrency: null,
      amount: 0,
      sourceCurrency: 'GBP'
    };

    this.formHandler = new FormHandler(
      this,
      new FxCalculatorModel(this.initialState),
      {
        submitError: ''
      }
    );

    this.formHandler.on('submitSuccess', data => {
      this.setState({
        exchangeInfo: data.data
      });
    });

    this.formHandler.on('submitError', data => {
      this.setState(
        {
          submitError: data.message
        },
        () => {
          setTimeout(() => {
            this.setState({
              submitError: '',
              exchangeInfo: null
            });
          }, 3000);
        }
      );
    });
  }

  _resetForm() {
    this.formHandler.setState({
      record: this.initialState
    });
  }

  _getForm() {
    const { formHandler } = this;

    return (
      <FormSubmitWrapper
        formHandler={formHandler}
        to="/"
        buttonText="Calculate"
        hideSubmit
      >
        <div className="login-input-wrapper">
          <AppDatePicker
            className="input-group"
            id="js-date-picker"
            value={this.state.record.transactionDate}
            name="transactionDate"
            label={i18n.t('Transaction date')}
            style={{ width: '100%' }}
            handler={formHandler}
            datePickerProps={{
              maxDate: new Date()
            }}
            // children={(
            //   <div className="time-picker-container"></div>
            // )}
          />
        </div>
        <div className="login-input-wrapper">
          <AppSelect
            className="input-group"
            materialProps={{
              fullWidth: true
            }}
            label={i18n.t('Currency')}
            textFieldProps={{
              label: i18n.t('Currency'),
              InputLabelProps: {
                shrink: true
              }
            }}
            model={CurrencyModel}
            name="transactionCurrency"
            value={this.state.record.transactionCurrency}
            handler={formHandler}
            extractName={option => `${option.code} (${option.name})`}
            extractValue={option => option.code}
          />
        </div>
        <div className="login-input-wrapper">
          <InputField
            className="input-group"
            materialProps={{
              fullWidth: true
            }}
            label={i18n.t('Transaction Amount')}
            name="amount"
            type="number"
            value={this.state.record.amount}
            handler={formHandler}
          />
        </div>
        <div className="login-input-wrapper">
          <AppRadioInput
            name="transactionType"
            labelText={i18n.t('Non-Euro Prepaid Card Purchases')}
            handler={formHandler}
            value="nonEuroPrepaidCardPurchases"
            checked={
              this.state.record.transactionType ===
              'nonEuroPrepaidCardPurchases'
            }
          />
          <AppRadioInput
            name="transactionType"
            labelText={i18n.t('Non-Euro ATM Withdrawals')}
            handler={formHandler}
            value="nonEuroAtmWithdrawals"
            checked={
              this.state.record.transactionType === 'nonEuroAtmWithdrawals'
            }
          />
        </div>
        <div className="login-input-wrapper">
          <InputField
            className="input-group"
            materialProps={{
              fullWidth: true,
              disabled: true
            }}
            autoComplete={this.state.record.password}
            label={i18n.t('Card currency')}
            value="GBP"
            editable={false}
            handler={formHandler}
          />
        </div>
        <div className="row">
          <div className="d-flex justify-content-center col-sm-6 mb-2">
            <AppButton type="submit" isLoading={this.state.isLoading}>
              <Trans>Calculate</Trans>
            </AppButton>
          </div>
          <div className="d-flex justify-content-center col-sm-6 mb-2">
            <AppButton onClick={() => this._resetForm()}>
              <Trans>Reset</Trans>
            </AppButton>
          </div>
        </div>
      </FormSubmitWrapper>
    );
  }

  _getResult() {
    const Wrapper = props => (
      <div className="col-sm-6 text-left fee-calculator">
        <SectionTitle>
          <Trans>Conversion summary</Trans>
        </SectionTitle>
        {props.children}
      </div>
    );

    const transactionFees: any = this.state.exchangeInfo || null;

    if (this.state.submitError) {
      return (
        <Wrapper>
          <div className="text-center">
            <em>{i18n.t(this.state.submitError)}</em>
          </div>
        </Wrapper>
      );
    }

    if (!transactionFees) {
      return (
        <Wrapper>
          <div className="text-center no-items">
            <em>
              <Trans>please submit the form</Trans>
            </em>
          </div>
        </Wrapper>
      );
    }

    const transactionFeesBankFeePct = transactionFees.bankFeePct;

    return (
      <Wrapper>
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            minHeight: 550,
            width: '100%'
          }}
        >
          <div
            style={{
              width: '100%',
              padding: 20,
              paddingTop: 0
            }}
          >
            <h6 className="text-center" style={{ fontWeight: 'bold' }}>
              <Trans>Your Card Currency Amount</Trans>
            </h6>
            <h2
              className="text-center"
              style={{ fontWeight: 'bold', padding: '15px 0' }}
            >
              {NumberHelper.currency(
                transactionFees.destinationAmount,
                transactionFees.destinationCurrency
              )}
            </h2>
            <p>
              <Trans>
                Effective Transaction Conversion Rate Including All Fees*:
              </Trans>
            </p>
            <p style={{ fontWeight: 'bold' }}>
              1 {transactionFees.sourceCurrency} ={' '}
              {transactionFees.effectiveRate}{' '}
              {transactionFees.destinationCurrency}
            </p>
          </div>
          <SectionTitle>
            <Trans>Transaction Fees</Trans>
          </SectionTitle>
          <div
            style={{
              textAlign: 'left',
              width: '100%',
              fontSize: 14
            }}
          >
            <span>
              <Trans>
                Non-Euro Prepaid Card Purchases are subject to a
                {{ transactionFeesBankFeePct }}% fee on the Mastercard
                conversion value for this transaction:
              </Trans>
            </span>
            <span style={{ fontWeight: 'bold' }}>
              {' '}
              {transactionFees.schemeAmount}{' '}
              {transactionFees.destinationCurrency} (1{' '}
              {transactionFees.sourceCurrency} = {transactionFees.schemeRate}{' '}
              {transactionFees.destinationCurrency})
            </span>
            <br />
            <br />
            <span style={{ paddingTop: 10 }}>
              <Trans>
                The published European Central Bank (ECB) conversion rate for
                this currency pair on your selected transaction date is:
              </Trans>
            </span>
            <br />
            <span style={{ fontWeight: 'bold' }}>
              1 {transactionFees.destinationCurrency} ={' '}
              {transactionFees.ecbRate} {transactionFees.sourceCurrency}
            </span>
            <br />
            <br />
            <span style={{ paddingTop: 10 }}>
              <Trans>Fee for this transaction:</Trans>{' '}
              <span style={{ fontWeight: 'bold' }}>
                {NumberHelper.currency(
                  transactionFees.bankFee,
                  transactionFees.destinationCurrency
                )}
              </span>
            </span>
            <br />
            <span>
              <Trans>Mastercard Fee for this transaction:</Trans>{' '}
              <span style={{ fontWeight: 'bold' }}>
                {NumberHelper.currency(
                  transactionFees.schemeFee,
                  transactionFees.destinationCurrency
                )}
              </span>
            </span>
            <br />
            <br />
            <span>
              <Trans>Total Fees:</Trans>{' '}
              <span style={{ fontWeight: 'bold' }}>
                {NumberHelper.currency(
                  transactionFees.totalFee,
                  transactionFees.destinationCurrency
                )}
              </span>
            </span>
            <br />
            <br />
            <span style={{ paddingTop: 10 }}>
              <Trans>These fees represent a</Trans>{' '}
              <span style={{ fontWeight: 'bold' }}>
                {transactionFees.totalFeePct}%
              </span>{' '}
              <Trans>
                increase on the ECB conversion value for this transaction.
              </Trans>
            </span>
            <br />
            <br />
            <span style={{ paddingTop: 10 }}>
              <Trans>
                Exchange rates can fluctuate and they may change between the
                time a transaction is made and the time it is deducted from your
                available balance. You agree that any change to the exchange
                rate may be applied immediately and without notice to you.
              </Trans>
            </span>
          </div>
        </div>
      </Wrapper>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-6 text-left">
          <SectionTitle>
            <Trans>Transaction details</Trans>
          </SectionTitle>
          {this._getForm()}
        </div>
        {this._getResult()}
      </div>
    );
  }
}

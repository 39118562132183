/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AuthService from '@launchpad/logic/services/AuthService';
import { AppIcon } from '../../../index';

function ThemeSwitcher(props: any) {
  const [theme, setTheme] = useState(props.theme);

  useEffect(() => {
    const localTheme = localStorage.getItem('AppTheme');

    document.documentElement.className = '';
    document.documentElement.classList.add(`theme-${localTheme}`);
  }, []);

  useEffect(() => {
    document.documentElement.className = '';
    document.documentElement.classList.add(`theme-${theme}`);
  }, [props.theme]);

  const changeTheme = (switchTheme: string) => {
    setTheme(switchTheme);
    AuthService.changeAppTheme(switchTheme);

    document.documentElement.className = '';
    document.documentElement.classList.add(`theme-${theme}`);
  };

  return (
    <div className="theme-switcher">
      {theme === 'dark' ? (
        <a
          href="javascript:void(0)"
          style={{ textDecoration: 'none', paddingLeft: 20 }}
          onClick={() => changeTheme('light')}
        >
          <AppIcon
            name="fa.sun-o"
            style={{ fontSize: 21, cursor: 'pointer' }}
          />
        </a>
      ) : (
        <a
          href="javascript:void(0)"
          style={{ textDecoration: 'none', paddingLeft: 20 }}
          onClick={() => changeTheme('dark')}
        >
          <AppIcon
            name="fa.moon-o"
            style={{ fontSize: 21, cursor: 'pointer' }}
          />
        </a>
      )}
    </div>
  );
}

const mapStateToProps = (state: { theme: any }) => {
  return {
    theme: state.theme
  };
};

export default connect(mapStateToProps)(ThemeSwitcher);

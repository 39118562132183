import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import CircularProgress from '@material-ui/core/CircularProgress';
import FaqModel from './model/FaqModel';
import { Trans } from 'react-i18next';
import i18n from '@launchpad/i18n';

export default class FaqPage extends Component {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      faq: [],
      loading: true
    };
  }

  componentDidMount() {
    new FaqModel().getFaq().then(faq => {
      this.setState({
        faq: faq.items,
        loading: false
      });
      if (faq.items.length === 0) {
        this.setState({
          loading: true
        });
      }
    });
  }

  _renderAllQuestions() {
    const items = [];
    const { faq } = this.state;
    faq.forEach((item, index) => {
      items.push(
        <Collapsible trigger={`${index + 1}. ${item.title}`}>
          <div
            style={{
              background: 'transparent',
              // paddingTop: 0
              paddingTop: 25
            }}
            dangerouslySetInnerHTML={{ __html: i18n.t(item.content) }}
            className="cms-content full-text"
          />
        </Collapsible>
      );
    });
    return items;
  }

  render() {
    const items = this._renderAllQuestions();
    return (
      <div className="appContainer" style={{ paddingBottom: 100 }}>
        <h1 className="legal-title">
          <Trans>FAQ</Trans>
        </h1>
        <div className="container">
          <div className="card-wrapper pt-3">
            {this.state.loading ? (
              <CircularProgress className="circle-progress" size={40} />
            ) : (
              <div className="col-12 white-box">{items}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

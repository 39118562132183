import BaseModel from '../../../logic/model/BaseModel';
import Api from '../../../logic/api/Api';

export default class CardModel extends BaseModel {
  /**
   * Get table name
   */
  getTableName() {
    return 'payment_devices';
  }

  async savePaymentDevices(paymentDevices) {
    // await this.deleteAll();
    // for (paymentDevice of paymentDevices) {
    //   await this.save(paymentDevices);
    // }
    await this.save(paymentDevices);
  }

  async refreshPaymentDevices() {
    const response = await Api.call('payment-device/list');

    if (response.success) {
      await this.savePaymentDevices(response.data);
      await this.refreshPaymentDevicesInStore();
    }
  }

  // async refreshPaymentDevicesInStore() {
  //   let paymentDevices = await this.find();
  //   await AppStore.dispatch(putPaymentDevicesInStore(paymentDevices));
  // }

  /**
   * Change freeze status
   *
   * @param {Object} paymentDevice PaymentDevice to change freeze status
   * @param {Bool} status Frozen or not
   */
  async changeFreezeStatus(paymentDevice, status) {
    const response = await this.freezePaymentDevice(paymentDevice.id, status);

    if (response.success) {
      await this.save(response.data.paymentDevice);
      await this.refreshPaymentDevicesInStore();
      return true;
    }

    return false;
  }

  async freezePaymentDevice(id, status) {
    const response = await Api.call(
      status ? 'payment-device/lock' : 'payment-device/unlock',
      {
        id,
        locked: !!status
      }
    );

    return response;
  }

  async replacePaymentDevice(id, reason) {
    const response = await Api.call('payment-device/replace', {
      id,
      reason
    });

    return response;
  }

  async fetchPin(paymentDevice) {
    const response = await Api.call('payment-device/pin', {
      id: paymentDevice.id
    });

    return response.data.pin;
  }

  async orderPhysicalPaymentDevice(pin) {
    const response = await Api.call('payment-device/order-physical', {
      pin
    });

    return response.success;
  }

  async activatePaymentDevice(paymentDevice, code) {
    const response = await Api.call('payment-device/activate', {
      id: paymentDevice.id,
      code
    });

    return response.success;
  }

  async activatePaymentDeviceWithAllPan(paymentDevice, secret) {
    const response = await Api.call('payment-device/activate', {
      id: paymentDevice.id,
      secret
    });

    return response.success;
  }

  async notificationsForPaymentDevice(id, status) {
    const response = await Api.call('payment-device/notifications/configure', {
      id,
      enabled: !!status
    });

    return response;
  }

  async loadPaymentDeviceImage(paymentDevice) {
    const response = await Api.call('payment-device/image', {
      id: paymentDevice.id
    });

    // Store it in file
    const fileUri = this.getPaymentDeviceImageFileLocation(paymentDevice);
    // let file = await FileSystem.writeAsStringAsync(fileUri, response.data.image);
    return true;
  }

  async paymentDeviceImageExists(paymentDevice) {
    return true;
    const fileUri = this.getPaymentDeviceImageFileLocation(paymentDevice);
    // let info = await FileSystem.getInfoAsync(fileUri);
    // return info.exists;
  }

  async getPaymentDeviceImageBase64(paymentDevice) {
    const fileUri = this.getPaymentDeviceImageFileLocation(paymentDevice);
    // return await FileSystem.readAsStringAsync(fileUri);
  }

  getPaymentDeviceImageFileLocation(paymentDevice) {
    // return FileSystem.documentDirectory + '/' + paymentDevice.id + '.txt';
  }
}

import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import AuthorisationModel from '@launchpad/modules/authorisations/models/AuthorisationModel';
import AuthorisationItem from '@launchpad/modules/authorisations/components/AuthorisationItem';
import { Trans } from 'react-i18next';
import { AppList, useSelector } from '../../../index';

function LatestAuthorisations() {
  const list = useRef(null);
  const modals = useSelector((state: any) => state.modals);
  const prevModalsRef = useRef<any>();

  // This useEffect runs only once, on mount
  useEffect(() => {
    prevModalsRef.current = modals;
  }, []);

  useEffect(() => {
    if (
      prevModalsRef.current &&
      prevModalsRef.current.length > 0 &&
      prevModalsRef.current[0].id === 'AUTHORISATION_DETAILS_MODAL'
    ) {
      refreshList();
    }
    // Now we update prevModalsRef.current for the next render
    prevModalsRef.current = modals;
  }, [modals]);

  function renderItem(item: any) {
    return <AuthorisationItem authorisation={item} key={item.id} />;
  }

  function refreshList() {
    if (list) {
      (list.current as any)._reset().then(() => {
        (list.current as any)._refresh();
      });
    }
  }

  return (
    <div style={{ position: 'relative', minHeight: 445 }}>
      <ul style={{ padding: 0, margin: 0, maxHeight: 390, overflow: 'hidden' }}>
        <AppList
          onInit={(appListRef: any) => {
            list.current = appListRef;
          }}
          model={AuthorisationModel}
          params={{
            limit: 5
          }}
          renderWrapper={(items: React.ReactNode) => (
            <ul style={{ padding: 0 }}>{items}</ul>
          )}
          renderItem={(item: any) => renderItem(item)}
          renderNoItems={() => (
            <div className="col-sm-12 well text-center">
              <div style={{ margin: '20px' }} className="no-items">
                <em>
                  <Trans>No new authorisations</Trans>
                </em>
              </div>
            </div>
          )}
        />
      </ul>

      <Link to="/authorisations" className="go-to-inbox">
        <Trans>All authorisations</Trans>
      </Link>
    </div>
  );
}

export default LatestAuthorisations;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import BottomWaveForCard from '../crud/BottomWaveForCard';
import { LPOverridable, OverrideService } from '../../index';
import LanguageSwitcher from '../layout/components/LanguageSwitcher';

class AppLayoutWithCard extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      language: ''
    };
  }

  componentDidUpdate(nextProps: any, prevProps: any) {
    if (
      nextProps.localization.locale !== this.props.localization.locale &&
      this.props.localization.locale
    ) {
      this.updateLanguage(nextProps.localization.locale);
    }
  }

  updateLanguage(locale: any) {
    this.setState({
      language: locale
    });
  }

  render() {
    return (
      <LPOverridable name="login.page.content">
        <div className="screen-layout" key={this.state.language}>
          <div className="app-card">
            <img
              className="logo"
              src={
                OverrideService.getStyles().logo ||
                require('../../assets/Launchpad_logoWhite.png')
              }
              alt="white-logo"
            />
            {OverrideService.getOverrides()['hide-language-switcher']
              ? null
              : this.props.localization.languages && (
                  <div className="auth-language-holder">
                    <LanguageSwitcher showCurrentLanguageName />
                  </div>
                )}
            <div className="child-container">{this.props.children}</div>
            <LPOverridable name="login.page.bottom">
              <BottomWaveForCard />
            </LPOverridable>
          </div>
          <LPOverridable name="login.page.bottomText">
            <div className="sign-up-holder">
              <p>
                <Trans>New user?</Trans>
              </p>
              <Link to="" className="sign-up-link">
                <Trans>Sign Up</Trans>
              </Link>
            </div>
          </LPOverridable>
        </div>
      </LPOverridable>
    );
  }
}

const mapStateToProps = state => {
  return {
    localization: state.localization
  };
};

export default connect(mapStateToProps)(AppLayoutWithCard);

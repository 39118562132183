import React from 'react';
import { connect } from 'react-redux';
import SwitchButton from '../../../../@launchpad/components/crud/SwitchButton';
import LoadingHelper from '../../../../@launchpad/util/LoadingHelper';
import AuthService from '../../../../@launchpad/logic/services/AuthService';
import Api from '../../../../@launchpad/logic/api/Api';
import { AppIcon } from '../../../../@launchpad/index';
import i18n from '@launchpad/i18n';
import { Trans } from 'react-i18next';

class FxNotificationsReports extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  handleFxNotifications(status: any) {
    LoadingHelper.executeAction(
      () => {
        return this.changeStatusFxNotifications(status);
      },
      {
        successMessage: status
          ? i18n.t('You enabled FX conversion notifications')
          : i18n.t('You disabled FX conversion notifications'),
        loadingMessage: i18n.t('Loading...'),
        failureMessage: i18n.t('An error ocurred, please try again later'),
        manualClose: false,
        renderIcon: (state: string) => {
          if (state === 'loading') return null;
          return (
            !status && <AppIcon name="fa.power-off" style={{ fontSize: 150 }} />
          );
        },
        beforeClose: () => {
          AuthService.refreshAuth();
        }
      }
    );
  }

  handleFxReportNotifications(status: any) {
    LoadingHelper.executeAction(
      () => {
        return this.changeStatusFxReportNotifications(status);
      },
      {
        successMessage: status
          ? i18n.t('You enabled monthly FX exchange reports')
          : i18n.t('You disabled monthly FX exchange reports'),
        loadingMessage: i18n.t('Loading...'),
        failureMessage: i18n.t('An error ocurred, please try again later'),
        manualClose: false,
        renderIcon: (state: string) => {
          if (state === 'loading') return null;
          return (
            !status && <AppIcon name="fa.power-off" style={{ fontSize: 150 }} />
          );
        },
        beforeClose: () => {
          AuthService.refreshAuth();
        }
      }
    );
  }

  async changeStatusFxNotifications(status: any) {
    const response = await Api.call('auth/user/notifications/fx/configure', {
      enabled: !!status
    });

    return response;
  }

  async changeStatusFxReportNotifications(status: any) {
    const response = await Api.call(
      'auth/user/notifications/fx-report/configure',
      {
        enabled: !!status
      }
    );

    return response;
  }

  render() {
    const { profile } = this.props;

    return (
      <>
        <li>
          <Trans>Receive FX conversion notifications</Trans>
          <SwitchButton
            isChecked={!!profile.fxNotifications}
            onChange={(val: any) => this.handleFxNotifications(val)}
          />
        </li>
        <li>
          <Trans>Receive monthly FX exchange reports</Trans>
          <SwitchButton
            isChecked={!!profile.fxReportNotifications}
            onChange={(val: any) => this.handleFxReportNotifications(val)}
          />
        </li>
      </>
    );
  }
}

const mapStateToProps = (state: { auth: { profile: any } }) => {
  return {
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps)(FxNotificationsReports);

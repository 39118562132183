import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  AppButton,
  BottomWaveForLoadingPage,
  OverrideService,
  LPOverridable
} from '../../../index';
import { Trans } from 'react-i18next';
import LanguageSwitcher from '@launchpad/components/layout/components/LanguageSwitcher';

class WelcomePage extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      language: ''
    };
  }

  componentDidMount(): void {
    const localTheme = localStorage.getItem('AppTheme');

    document.documentElement.className = '';
    document.documentElement.classList.add(`theme-${localTheme}`);
  }

  componentDidUpdate(nextProps: any, prevProps: any) {
    if (
      nextProps.localization.locale !== this.props.localization.locale &&
      this.props.localization.locale
    ) {
      this.updateLanguage(nextProps.localization.locale);
    }
  }

  updateLanguage(locale: any) {
    this.setState({
      language: locale
    });
  }

  goToLoginPage() {
    this.props.history.push('/login');
  }

  render() {
    return (
      <LPOverridable name="welcome.page.content" history={this.props.history}>
        <div className="login-overlay-container" key={this.state.language}>
          <img
            className="logo"
            src={
              OverrideService.getStyles().logo ||
              require('../../../assets/Launchpad_logoWhite.png')
            }
            alt="logo"
          />
          {OverrideService.getOverrides()['hide-language-switcher']
            ? null
            : this.props.localization.languages && (
                <div className="auth-language-holder">
                  <LanguageSwitcher showCurrentLanguageName />
                </div>
              )}
          <img
            src={require('../../../assets/overlay_background.png')}
            className="overlay-background"
            alt=""
          />
          <div className="center-element">
            <h1>
              <Trans>Welcome to Launchpad</Trans>
            </h1>
            <h3>
              <Trans>Launch Your Digital Banking Strategy with us</Trans>
            </h3>
            <AppButton onClick={() => this.goToLoginPage()}>
              <Trans>SIGN IN</Trans>
            </AppButton>
          </div>

          <BottomWaveForLoadingPage />

          <div className="sign-up-holder">
            <p>
              <Trans>New user?</Trans>
            </p>
            <Link to="" className="sign-up-link">
              <Trans>Sign Up</Trans>
            </Link>
          </div>
        </div>
      </LPOverridable>
    );
  }
}

const mapStateToProps = (state: { theme: any; localization: any }) => {
  return {
    theme: state.theme,
    localization: state.localization
  };
};

export default connect(mapStateToProps)(WelcomePage);

import React from 'react';
import { LPOverridable } from '@launchpad/components';
import ModalDialog from '../../../components/modal/ModalDialog';
import ModalService from '../../../logic/services/ModalService';
import NumberHelper from '../../../util/NumberHelper';
import DateHelper from '../../../util/DateHelper';
import { AppIcon, AppButton } from '../../../index';
import { getCurrencySymbol } from '../../../util/CurrencyHelper';
import OverrideService from '../../../logic/services/overrides/OverrideService';
import { Trans } from 'react-i18next';

export default class LoadModal extends React.PureComponent<any, any> {
  render() {
    const { paymentDevice } = this.props;
    const { profile } = this.props;

    return (
      <ModalDialog id="LOAD_CARD_MODAL" show>
        <div
          className="transaction-modal"
          style={OverrideService.getOverrides()['custom-load-modal-style']}
        >
          <div className="close-btn">
            <AppIcon
              name="fa.times"
              style={{
                fontSize: 24,
                cursor: 'pointer'
              }}
              onClick={() => ModalService.hideModal()}
            />
          </div>
          <LPOverridable name="load-account-content" profile={profile}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'column',
                paddingBottom: 10
              }}
            >
              <h3 className="load-title">
                <Trans>Load Card</Trans>
              </h3>
              <p className="load-subtitle">
                <Trans>
                  Use the details below to load your card directly from your
                  bank account
                </Trans>
              </p>
              <div className="row justify-content-center align-items-center load-info">
                <div className="col-sm-6 column first-line">
                  <span>
                    <Trans>Payee</Trans>
                  </span>
                  <span>
                    <Trans>Sort code</Trans>
                  </span>
                  <span>
                    <Trans>Account No</Trans>
                  </span>
                  <span>
                    <Trans>IBAN</Trans>
                  </span>
                  <span>
                    <Trans>SWIFT/BICSWIFT</Trans>
                  </span>
                  <span>
                    <Trans>Currency</Trans>
                  </span>
                </div>
                <div className="col-sm-6 column second-line">
                  <span>
                    <Trans>Pannovate</Trans>
                  </span>
                  <span>
                    <Trans>12-34-56</Trans>
                  </span>
                  <span>
                    <Trans>1234567890</Trans>
                  </span>
                  <span>
                    <Trans>PK99BANK0123456789</Trans>
                  </span>
                  <span>
                    <Trans>AMADAGB11</Trans>
                  </span>
                  <span>{getCurrencySymbol(paymentDevice.currency)}</span>
                </div>
              </div>

              <div className="customer-no">
                <p>
                  <Trans>Your Customer no</Trans>
                </p>
                <div className="ref-number-holder">
                  <span>{profile.customerReference}</span>
                </div>
                <h6>
                  <Trans>
                    Please include this number in the reference of your payment
                    so that we can ensure prompt credit to your account
                  </Trans>
                </h6>
              </div>
            </div>
          </LPOverridable>
          <LPOverridable name="send-request-btn">
            <div
              style={{
                display: 'flex',
                flex: 0.5,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <AppButton customstyle={{ maxWidth: 450, maxHeight: 60 }}>
                <Trans>SEND REQUEST</Trans>
              </AppButton>
            </div>
          </LPOverridable>
        </div>
      </ModalDialog>
    );
  }
}

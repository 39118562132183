import React, { Component } from 'react';
import NumberHelper from '../../../../util/NumberHelper';
import DateHelper from '../../../../util/DateHelper';
import { Trans } from 'react-i18next';
import i18n from '@launchpad/i18n';

export default class CountryTransaction extends Component {
  render() {
    let item = this.props.transaction;
    let amount = null;
    if (item.type === 'spend') {
      amount = (
        <span style={{ fontSize: 12, alignSelf: 'flex-end' }}>
          -{NumberHelper.currency(Math.abs(item.amount, true))}
        </span>
      );
    } else {
      amount = (
        <span style={{ fontSize: 12, alignSelf: 'flex-end' }}>
          {NumberHelper.currency(item.amount, true)}
        </span>
      );
    }
    return (
      <li className="notificationItem transactionItem statements">
        <div style={{ display: 'flex', flex: 1 }}>
          {/* <span className='iconHolder' style={{ minWidth: 40 }}>
          <i className={'fa' + ' ' + icon} aria-hidden={true} style={{fontSize: 21 }}></i>
        </span> */}
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ color: '#201d5c', fontWeight: 'bold' }}>
              {i18n.t(item.name)}
            </span>
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          {item.donationAmount ? (
            <span
              style={{
                fontSize: 12,
                alignSelf: 'flex-end',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <i
                className="fa fa-heart"
                aria-hidden={true}
                style={{ color: '#201d5c', fontSize: 21, paddingRight: '10px' }}
              ></i>
              <p>
                {NumberHelper.currency(item.donationAmount, true)}{' '}
                <Trans>Donated</Trans>
              </p>
            </span>
          ) : null}

          <span style={{ fontSize: 12, alignSelf: 'flex-end' }}>
            {DateHelper.date(item.transactionDate)}
          </span>
          {/* <span style={{ fontSize: 12, alignSelf: 'flex-end' }}>{NumberHelper.currency(item.amount, true)}</span> */}
          {amount}
        </div>
      </li>
    );
  }
}

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import OverrideService from '@launchpad/logic/services/overrides/OverrideService';
import { Trans } from 'react-i18next';
import i18n from '@launchpad/i18n';
import BaseModel from '../../logic/model/BaseModel';
import AppIcon from '../../components/elements/icon/AppIcon';
import FXRatesItem from './FXRatesItem';
import ModalDialog from '../../components/modal/ModalDialog';
import AppButton from '../../components/crud/AppButton';
import AppList from '../../components/crud/AppList';
import InputField from '../../components/elements/form/InputField';
import ExchangeConfirmationDialog from './ExchangeConfirmationDialog';

const FXRatesModal = ({ balances, visible, onClose }) => {
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(
    false
  );
  const [baseAmount, setBaseAmount] = useState('');
  const [convertedAmount, setConvertedAmount] = useState('');
  const [baseCurrency, setBaseCurrency] = useState(null);
  const [convertedCurrency, setConvertedCurrency] = useState(null);
  const [rates, setRates] = useState([]);
  const [fieldValidated, setFieldValidated] = useState(null);

  // Rerender component when props visible is changed
  useEffect(() => {
    setBaseAmount('');
    setConvertedAmount('');
    // Set main currency
    const mainBalance = balances.find(balance => balance.isMain);
    setBaseCurrency(mainBalance);
    setConvertedCurrency(null);
    setFieldValidated(true);
  }, [visible]);

  // Recalculate amount fields when currency changed
  useEffect(() => {
    if (baseCurrency && convertedCurrency) {
      recalculateAmountFields();
    }
  }, [baseCurrency, convertedCurrency]);

  // Set chosen currency in state
  function handleCurrencySelected(
    value,
    currencySetterCallback,
    currencyGetter
  ) {
    // Prevent setting default value if same value is chosen
    if (!currencyGetter || currencyGetter.id !== value.id) {
      // If that currency is alredy chosen, set other value to default
      if (
        (baseCurrency && baseCurrency.id === value.id) ||
        (convertedCurrency && convertedCurrency.id === value.id)
      ) {
        setConvertedCurrency(null);
        setBaseCurrency(null);
      }
    }

    currencySetterCallback(value);
  }

  /**
   * Set inserted amount in state, and calculate other field amount
   * @param {number} value - inserted value
   * @param {string} name - name of field (base-amount || converted-amount)
   */
  function handleAmountChanged(value, name) {
    setFieldValidated(true);
    // Calculate value of other field
    if (name === 'base-amount') {
      setBaseAmount(value);
      if (convertedCurrency && baseCurrency) {
        const chosenRate = rates.find(
          rate => rate.currency === convertedCurrency.currency
        );
        const baseRate = rates.find(
          rate => rate.currency === baseCurrency.currency
        );
        if (chosenRate && baseRate) {
          const amount = calculateAmountsBasedOnCurrency(
            value,
            baseRate.rate,
            chosenRate.rate
          );
          setConvertedAmount(amount.toFixed(2));
        }
      }
    } else {
      setConvertedAmount(value);
      if (baseCurrency && convertedCurrency) {
        const chosenRate = rates.find(
          rate => rate.currency === baseCurrency.currency
        );
        const baseRate = rates.find(
          rate => rate.currency === convertedCurrency.currency
        );
        if (chosenRate && baseRate) {
          const amount = calculateAmountsBasedOnCurrency(
            value,
            baseRate.rate,
            chosenRate.rate
          );
          setBaseAmount(amount.toFixed(2));
        }
      }
    }
  }

  // Calculate amount based on given rate
  function calculateAmountsBasedOnCurrency(amount, baseRate, convertedRate) {
    return (convertedRate / baseRate) * amount;
  }

  // Recalculate amount fields when another currency is selected
  function recalculateAmountFields() {
    setFieldValidated(true);
    if (baseAmount) {
      const chosenRate = rates.find(
        rate => rate.currency === convertedCurrency.currency
      );
      const baseRate = rates.find(
        rate => rate.currency === baseCurrency.currency
      );
      const amount = calculateAmountsBasedOnCurrency(
        baseAmount,
        baseRate.rate,
        chosenRate.rate
      );
      setConvertedAmount(amount.toFixed(2));
    } else if (convertedAmount) {
      const chosenRate = rates.find(
        rate => rate.currency === baseCurrency.currency
      );
      const baseRate = rates.find(
        rate => rate.currency === convertedCurrency.currency
      );
      const amount = calculateAmountsBasedOnCurrency(
        convertedAmount,
        baseRate.rate,
        chosenRate.rate
      );
      setBaseAmount(amount.toFixed(2));
    }
  }

  // Check if all values are set and show confirmation dialog
  function _handelExchangeButtonPressed() {
    if (baseCurrency && convertedCurrency && baseAmount && convertedAmount) {
      setConfirmationModalVisible(true);
    } else {
      // Show red border where data is missing
      setFieldValidated(false);
    }
  }

  // Calculate rate based on the base currency
  function calculateRate(item) {
    if (baseCurrency.isMain) return item.rate;

    if (item.currency === baseCurrency.currency) return 1;

    const baseRate = rates.find(br => br.currency === baseCurrency.currency);
    return (item.rate / baseRate.rate).toFixed(2);
  }

  // Header text with close button
  function renderHeader() {
    return (
      <div className="fx-redeem-header">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 10,
            alignItems: 'center'
          }}
        >
          <img
            src={require('../../assets/currency-euro-dollar-exchange.svg')}
            alt="Exchange office"
            style={{ paddingRight: 10 }}
          />
          <p
            style={{
              textAlignVertical: 'center',
              fontWeight: 'bold',
              fontSize: 18
            }}
          >
            <Trans>FX Rates</Trans>
          </p>
        </div>
        <div className="close-btn">
          <AppIcon
            name="fa.times"
            style={{
              fontSize: 24,
              cursor: 'pointer'
            }}
            onClick={() => onClose()}
          />
        </div>
      </div>
    );
  }

  const customStyles = {
    placeholder: () => ({
      color: !fieldValidated
        ? OverrideService.getThisOrOverridenColors().error_color
        : OverrideService.getThisOrOverridenColors().gray_color
    }),
    control: provided => ({
      ...provided,
      borderColor: OverrideService.getThisOrOverridenColors().main_color,
      boxShadow: 'none !important',
      backgroundColor: 'transparent',
      '&:hover': {
        borderColor: OverrideService.getThisOrOverridenColors().main_color,
        boxShadow: 'none !important'
      }
    }),
    option: (provided, { isSelected, isFocused }) => ({
      ...provided,
      color:
        isSelected || isFocused
          ? 'white'
          : OverrideService.getThisOrOverridenColors().gray_color,
      backgroundColor: isSelected
        ? OverrideService.getThisOrOverridenColors().gray_color
        : [
            isFocused
              ? OverrideService.getThisOrOverridenColors().gray_color
              : 'white'
          ]
    })
  };

  function renderContent() {
    return (
      <div className="fx-redeem-content">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            paddingHorizontal: 10
          }}
        >
          <div style={{ width: '45%' }}>
            <Select
              classNamePrefix="fx-rates-select"
              placeholder={i18n.t('Choose currency')}
              styles={customStyles}
              value={baseCurrency}
              onChange={value =>
                handleCurrencySelected(value, setBaseCurrency, baseCurrency)
              }
              options={balances}
              getOptionLabel={option => {
                return (
                  <FXRatesItem
                    key={option.id}
                    currency={option.currency}
                    imageStyle={{ width: 30, height: 30 }}
                    text={
                      option.currencyName
                        ? option.currencyName
                        : option.currency
                    }
                  />
                );
              }}
              getOptionValue={option => {
                return (
                  <FXRatesItem
                    key={option.id}
                    currency={option.currency}
                    imageStyle={{ width: 30, height: 30 }}
                    text={
                      option.currencyName
                        ? option.currencyName
                        : option.currency
                    }
                  />
                );
              }}
            />
          </div>
          <div style={{ width: '45%' }}>
            <Select
              classNamePrefix="fx-rates-select"
              placeholder={i18n.t('Choose currency')}
              styles={customStyles}
              value={convertedCurrency}
              onChange={value =>
                handleCurrencySelected(
                  value,
                  setConvertedCurrency,
                  convertedCurrency
                )
              }
              options={balances}
              getOptionLabel={option => {
                return (
                  <FXRatesItem
                    key={option.id}
                    currency={option.currency}
                    imageStyle={{ width: 30, height: 30 }}
                    text={
                      option.currencyName
                        ? option.currencyName
                        : option.currency
                    }
                  />
                );
              }}
              getOptionValue={option => {
                return (
                  <FXRatesItem
                    key={option.id}
                    currency={option.currency}
                    imageStyle={{ width: 30, height: 30 }}
                    text={
                      option.currencyName
                        ? option.currencyName
                        : option.currency
                    }
                  />
                );
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingTop: 20,
              width: '100%'
            }}
          >
            <InputField
              className="input-group"
              materialProps={{
                fullWidth: true,
                variant: 'standard',
                error: !fieldValidated
              }}
              label={i18n.t('Enter amount')}
              name="base-amount"
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              value={`${baseAmount}`}
              onInputText={(value, name) => handleAmountChanged(value, name)}
            />
            <AppIcon
              name="fa.arrow-right"
              style={{
                fontSize: 15,
                width: '10%',
                alignSelf: 'center',
                padding: 10
              }}
            />
            <InputField
              className="input-group"
              materialProps={{
                fullWidth: true,
                variant: 'standard',
                error: !fieldValidated
              }}
              label={i18n.t('Enter amount')}
              name="converted-amount"
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              value={`${convertedAmount}`}
              onInputText={(value, name) => handleAmountChanged(value, name)}
            />
          </div>
        </div>
        <div style={{ padding: 15 }}>
          <AppList
            scrollEnabled
            listProps={{ scrollEnabled: true }}
            model={FXRatesModel}
            // Set rates from list in state, for amount conversion
            afterDataLoaded={items => setRates(items)}
            renderItem={item => {
              let calculatedRate = item.rate;
              if (baseCurrency) {
                calculatedRate = calculateRate(item);
              }
              return (
                <FXRatesItem {...item} text={calculatedRate} key={item.id} />
              );
            }}
          />
        </div>
        <h6 className="p-3 text-center">
          <Trans>
            The rate shown here is approximate, the actual rate will be shown
            after clicking the Exchange button.
          </Trans>
        </h6>
      </div>
    );
  }

  // Exchange button at the bottom
  function renderFooter() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <AppButton
          type="submit"
          onClick={() => _handelExchangeButtonPressed()}
          style={{ width: 500 }}
        >
          <Trans>Exchange</Trans>
        </AppButton>
      </div>
    );
  }

  return (
    <div>
      <ModalDialog show={visible} onClose={() => onClose()}>
        <div className="fx-redeem-holder">
          {!confirmationModalVisible ? (
            <div>
              {renderHeader()}
              <div style={{ display: 'flex', flex: 1, width: '100%' }}>
                {renderContent()}
              </div>
              {renderFooter()}
            </div>
          ) : (
            <ExchangeConfirmationDialog
              amount={baseAmount}
              baseCurrency={baseCurrency}
              convertedCurrency={convertedCurrency}
              onHide={shouldClose => {
                setConfirmationModalVisible(false);
                if (shouldClose) {
                  onClose();
                }
              }}
            />
          )}
        </div>
      </ModalDialog>
    </div>
  );
};

const mapStateToProps = state => {
  // TODO @dario: Don't always use first payment device!
  return {
    balances: state.paymentDevices[0].balances
  };
};

export default connect(mapStateToProps)(FXRatesModal);

class FXRatesModel extends BaseModel {
  getListUrl() {
    return 'rates';
  }
}

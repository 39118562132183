import { AppButton, AppIcon, ModalDialog } from '@launchpad/components';
import ModalService from '@launchpad/logic/services/ModalService';
import { currency } from '@launchpad/util/NumberHelper';
import { date } from '@launchpad/util/DateHelper';
import React from 'react';
import { Trans } from 'react-i18next';
import i18n from '@launchpad/i18n';
import Api from '@launchpad/logic/api/Api';

const NAME = 'AUTHORISATION_DETAILS_MODAL';
const AuthorizationDetailsModal = props => {
  const item = props.authorisation;
  const transactionInfo = item['3ds_authentication_request'];

  const dataToShow = [
    {
      label: i18n.t('Merchant name:'),
      className: 'transaction-type',
      value: transactionInfo.merchant_name
    },
    {
      label: i18n.t('Created at:'),
      className: 'transaction-status',
      value: date(item.created_at)
    },
    {
      label: i18n.t('Status:'),
      className: 'transaction-status',
      value: transactionInfo.status
    },
    {
      label: i18n.t('Authorisation code:'),
      className: 'transaction-type',
      value: item.authorization_code
    }
  ];

  // Format and display the transaction amount

  let amount: JSX.Element | null = null;
  amount = currency(
    transactionInfo.transaction_amount,
    transactionInfo.transaction_currency
  );

  async function finalAuthApproval(authorization) {
    Api.call(`authenticator/v1/authorizations/${authorization.id}/update`, {
      data: {
        confirm: true,
        authorizationCode: authorization.authorizationCode
      }
    }).then(() => {
      // Transaction approved, show success modal
      ModalService.hideModal(NAME);
      ModalService.showModal('APPROVED_TRANSACTION', {
        authorization
      });
    });
  }

  // Async method to handle the submission of the decline action
  async function handleSubmitAuth(authID, confirmed, authorizationCode) {
    if (confirmed) {
      if (transactionInfo.status === 'otp_completed') {
        // If otp completed, confirm auth
        finalAuthApproval(item);
      } else {
        // initial auth confirmation and show passcode modal
        // Ask for OTP/password and approve the transaction
        Api.call(`authenticator/v1/authorizations/${authID}/update`, {
          data: {
            confirm: true,
            authorizationCode
          }
        }).then((result: any) => {
          if (
            result.data &&
            result.data.items &&
            result.data.items.length > 0
          ) {
            const authorization = result.data.items[0];
            // Show modal for inserting passcode
            ModalService.showModal('GENERAL_INSERT_PASSCODE', {
              authorization,
              hash: authorization?.['3ds_authentication_request']?.hash,
              callback: () => {
                finalAuthApproval(authorization);
              }
            });
          } else {
            // Something went wrong, show an error modal
            const message =
              result.message || 'Something went wrong, please try again later.';
            const innerModalContent = (
              <h3
                style={{
                  marginTop: 20,
                  textAlign: 'center',
                  fontSize: '1.2rem'
                }}
              >
                <Trans>{message}</Trans>
              </h3>
            );
            ModalService.showModal('INFO_MODAL', {
              innerElements: innerModalContent,
              modalIcon: 'icon_remove',
              iconStyle: { fontSize: 70 },
              closeable: true
            });
          }
        });
      }
    }
  }

  async function handleDeclineAuth(authID, authorizationCode) {
    // Call the API to update authorization status
    const response: any = await Api.call(
      `authenticator/v1/authorizations/${authID}/update`,
      {
        data: {
          confirm: false,
          authorizationCode
        }
      }
    );
    // Check if the API call was successful
    if (response.success) {
      // Redirect to the merchant app if provided
      if (response.data.merchantAppRedirectURL) {
        window.location.href = response.data.merchantAppRedirectURL;
        return;
      }
      // If no redirect URL, hide the modal and show an info modal
      ModalService.hideModal(NAME);
      const innerModalContent = (
        <h3 style={{ marginTop: 20, textAlign: 'center', fontSize: '1.2rem' }}>
          <Trans>You declined this payment.</Trans>
          <br />
          <Trans>Please return to the merchant screen.</Trans>
        </h3>
      );
      ModalService.showModal('INFO_MODAL', {
        innerElements: innerModalContent,
        modalIcon: 'icon_remove',
        iconStyle: { fontSize: 70 },
        closeable: true
      });
    } else {
      // If API call fails, hide the modal and show an error info modal
      ModalService.hideModal(NAME);
      const innerModalContent = (
        <h3 style={{ marginTop: 20, textAlign: 'center', fontSize: '1.2rem' }}>
          <Trans>Something went wrong, please try again!</Trans>
        </h3>
      );
      ModalService.showModal('INFO_MODAL', {
        innerElements: innerModalContent,
        modalIcon: 'icon_remove',
        iconStyle: { fontSize: 70 },
        closeable: true
      });
    }
  }

  return (
    <ModalDialog id="AUTHORISATION_DETAILS_MODAL" show>
      <div className="transaction-modal" style={{ overflow: 'hidden' }}>
        <div className="close-btn">
          <AppIcon
            name="fa.times"
            style={{
              fontSize: 24,
              cursor: 'pointer'
            }}
            onClick={() => ModalService.hideModal(NAME)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            minWidth: '80%'
          }}
        >
          <h4>{i18n.t(item.title)}</h4>
          <span style={{ textAlign: 'center', marginTop: 10 }}>
            {i18n.t(item.description)}
          </span>
          <span className="icon-holder">
            <i className="fa fa-info" aria-hidden style={{ fontSize: 31 }} />
          </span>
          <div>{date(item.created_at)}</div>
          <p className="tx-amount">{amount}</p>
          <div className="transaction-details">
            {dataToShow.map((x, index) => {
              return (
                <div
                  className={`row ${x.className ? x.className : ''}`}
                  key={`transaction${index}`}
                >
                  <div className="col-xs-6 detail-label">{i18n.t(x.label)}</div>
                  <div className="col-xs-6 detail-value">{i18n.t(x.value)}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <AppButton
            customstyle={{ maxWidth: 450, maxHeight: 60, minWidth: 200 }}
            onClick={() => handleDeclineAuth(item.id, item.authorization_code)}
          >
            <Trans>Decline</Trans>
          </AppButton>
          <div style={{ width: 20 }} />
          <AppButton
            customstyle={{ maxWidth: 450, maxHeight: 60, minWidth: 200 }}
            onClick={() =>
              handleSubmitAuth(item.id, true, item.authorization_code)
            }
          >
            <Trans>Approve</Trans>
          </AppButton>
        </div>
      </div>
    </ModalDialog>
  );
};

export default AuthorizationDetailsModal;

// import React from 'react';
import colors from '../../styles/_variables.scss';

const styles = {
  colors,

  APP_NAME: 'Prosper²',
  // APP_NAME: 'Prosper² Rewards',

  // ASSETS
  logo: require('../../assets/img/Prosper2-logo.png'),
  // logo: require('../../assets/img/rewards/rewards-logo.png'),
  frontCard: require('../../assets/img/Prosper-Card-Front.png'),
  backCard: require('../../assets/img/Prosper-Card-Back.png'),
  activateCardImage: require('../../assets/img/activate_card.png')
};

// export const variants = {
//   prosper: {
//     APP_NAME: 'Prosper²',
//     logo: require('../../assets/img/Prosper2-logo.png'),
//     frontCard: require('../../assets/img/Prosper-Card-Front.png'),
//     backCard: require('../../assets/img/Prosper-Card-Back.png')
//   },
//   'prosper-rewards': {
//     APP_NAME: 'Prosper² Rewards',
//     logo: require('../../assets/img/rewards/rewards-logo.png')
//   }
// };

// const currentVariant = process.env.REACT_APP_VARIANT || 'prosper';

// export default { ...styles, ...variants[currentVariant] };
export default { ...styles };

import AuthService from '@launchpad/logic/services/AuthService';
import LoadingHelper from '@launchpad/util/LoadingHelper';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppIcon, SwitchButton, LPOverridable } from '../../../index';
import { currency } from '../../../util/NumberHelper';
import CardModel from '../models/CardModel';
import OverrideService from '@launchpad/logic/services/overrides/OverrideService';
import i18n from '@launchpad/i18n';
import { Trans } from 'react-i18next';

class CardDetails extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      autoTopUp: false,
      notifications: false,
      disableInternet: false
    };
  }

  handleAutoTopUpModal() {
    this.setState({ autoTopUp: !this.state.autoTopUp });
    if (!this.state.autoTopUp === true) {
      this.setState({ showModal: true });
    }
  }

  handleDisableInternet() {
    this.setState({ disableInternet: !this.state.disableInternet });
  }

  handleNotifications(id: any, status: any) {
    LoadingHelper.executeAction(
      () => {
        return new CardModel().notificationsForPaymentDevice(id, status);
      },
      {
        successMessage: status
          ? i18n.t('You enabled Payment Device notifications')
          : i18n.t('You disabled Payment Device notifications'),
        loadingMessage: i18n.t('Loading...'),
        failureMessage: i18n.t('An error ocurred, please try again later'),
        manualClose: false,
        renderIcon: (state: string) => {
          if (state === 'loading') return null;
          return (
            !status && <AppIcon name="fa.power-off" style={{ fontSize: 150 }} />
          );
        },
        beforeClose: () => {
          AuthService.refreshAuth();
        }
      }
    );
  }

  render() {
    const { card } = this.props;
    return (
      <div className="row details-holder">
        <div className="top-line" />
        <div className="col-md-5">
          <ul>
            <li>
              <Trans>Status:</Trans>{' '}
              <span>
                {i18n.t(card.statusText)}{' '}
                <AppIcon
                  name="fa.circle"
                  style={{
                    fontSize: 10,
                    bottom: 2,
                    position: 'relative',
                    color:
                      card.statusText === 'OK'
                        ? OverrideService.getThisOrOverridenColors()
                            .success_color
                        : OverrideService.getThisOrOverridenColors().error_color
                  }}
                />
              </span>
            </li>
            <li>
              <Trans>Total balance</Trans>:{' '}
              <span>{currency(card.balance, card.currency)}</span>
            </li>
            <li>
              <Trans>ID</Trans>: <span>{card.pan}</span>
            </li>
            <LPOverridable name="card.expires">
              <li>
                <Trans>Expires</Trans>: <span>{card.expires}</span>
              </li>
            </LPOverridable>
            <li>
              <LPOverridable name="card.page.linkTC">
                <Link to="/terms-conditions" className="link-for-terms">
                  <Trans>Terms & Conditions</Trans>
                </Link>
              </LPOverridable>
            </li>
            <LPOverridable name="card.page.fx-calculator">
              <li>
                <Link to="/fx-calculator" className="link-for-terms">
                  <Trans>Exchange Fee Calculator</Trans>
                </Link>
              </li>
            </LPOverridable>
          </ul>
        </div>
        <div className="col-md-7">
          <ul className="control-card">
            <LPOverridable name="card.page.autoTopUp">
              <li>
                <Trans>Auto Top up</Trans>
                <SwitchButton
                  isChecked={this.state.autoTopUp}
                  onChange={() => this.handleAutoTopUpModal()}
                />
              </li>
            </LPOverridable>
            <LPOverridable name="card.page.disableInternet">
              <li>
                <Trans>Disable use on the internet</Trans>
                <SwitchButton
                  isChecked={this.state.disableInternet}
                  onChange={() => this.handleDisableInternet()}
                />
              </li>
            </LPOverridable>
            <li>
              <Trans>Notifications</Trans>
              <SwitchButton
                isChecked={!!card.notificationsEnabled}
                onChange={(val: any) => this.handleNotifications(card.id, val)}
              />
            </li>
            <LPOverridable name="card.action.FxNotificationsReports">
              <div />
            </LPOverridable>
          </ul>
        </div>
      </div>
    );
  }
}

export default CardDetails;

import { fork, all, put, takeEvery } from 'redux-saga/effects';
import AuthService from '@launchpad/logic/services/AuthService';
import AuthSagas from '../../modules/auth/AuthSagas';
import { APP_MOUNTED } from './AppActions';
import {
  MEMBER_TOKEN_LOADED,
  CHANGE_THEME
} from '../../modules/auth/AuthActions';
import Config from '../../config';
import LocalizationService from '../localization/LocalizationService';

/**
 * Logout user
 *
 * @param {Object} action
 */
function* appMounted(action) {
  try {
    yield new LocalizationService().loadDictionary();
    yield Config.load();

    const token = window.localStorage.getItem('AUTH_TOKEN');
    const theme = window.localStorage.getItem('AppTheme');

    if (!theme) {
      AuthService.changeAppTheme('light');
    }

    yield put({ type: CHANGE_THEME, payload: theme });

    if (token) {
      yield put({ type: MEMBER_TOKEN_LOADED, payload: token });
    }
  } catch (e) {}
}

export function* watchAppMounted() {
  yield takeEvery(APP_MOUNTED, appMounted);
}

export default function* allSagas() {
  yield all([fork(watchAppMounted), fork(AuthSagas)]);
}

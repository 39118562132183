import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import LoginModel from '../models/LoginModel';
import { loginTriggerAction } from '../AuthActions';
import {
  AppLayoutWithCard,
  FormSubmitWrapper,
  FormHandler,
  InputField,
  AppCheckboxInput,
  OverrideService
} from '../../../index';
import { Trans } from 'react-i18next';
import i18n from '../../../i18n';

class LoginPage extends Component {
  // formHandler: FormHandler;

  constructor(props) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new LoginModel({
        email: ' ',
        password: ' '
      }),
      {
        redirect: false
      }
    );
    this.formHandler.on('submitSuccess', data => {
      if (data.success) {
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 3000);
      }
    });
  }

  componentDidMount() {
    this.setState(state => ({
      ...state,
      record: {
        ...state.record,
        email: '',
        password: '',
        remember: false
      }
    }));
    const localTheme = localStorage.getItem('AppTheme');

    document.documentElement.className = '';
    document.documentElement.classList.add(`theme-${localTheme}`);
  }

  onSubmitSuccess(data) {
    if (data.success && data.data.token) {
      this.props.userLoggedIn(data.data.token, data.data.account);
    }
  }

  _getModalMessage(data) {
    if (!data.success) {
      return data.message;
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    const { formHandler } = this;
    return (
      <AppLayoutWithCard>
        <h4>
          <Trans>SIGN IN</Trans>
        </h4>
        <div className="form-wrapper">
          <FormSubmitWrapper
            formHandler={formHandler}
            buttonClass="gold-button"
            invertedBtn={
              OverrideService.getOverrides()['inverted-button'] || false
            }
            to="/"
            buttonText="SIGN IN"
            getModalMessage={data => this._getModalMessage(data)}
            key={this.props.localization.locale}
          >
            <div className="login-input-wrapper">
              <InputField
                className="input-group"
                materialProps={{
                  fullWidth: true
                }}
                autoComplete={this.state.record.email}
                label={i18n.t('E-mail')}
                name="email"
                type="email"
                // placeholder={'E-mail'}
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.record.email}
                handler={formHandler}
              />
            </div>
            <div className="login-input-wrapper">
              <InputField
                className="input-group"
                materialProps={{
                  fullWidth: true
                }}
                autoComplete={this.state.record.password}
                label={i18n.t('Password')}
                type="password"
                name="password"
                // placeholder={'Password'}
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.record.password}
                handler={formHandler}
              />
            </div>
          </FormSubmitWrapper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 20,
              marginBottom: 50
            }}
          >
            <Link
              to="/forgot-password"
              style={{
                textDecoration: 'none',
                fontWeight: 'lighter',
                fontSize: 15
              }}
            >
              <Trans>Forgot Password?</Trans>
            </Link>
          </div>

          <AppCheckboxInput
            name="remember"
            id="rememberField"
            htmlFor="rememberField"
            labelText={i18n.t('Remember me next time')}
            value={this.state.remember}
            handler={this.formHandler}
          />
        </div>
      </AppLayoutWithCard>
    );
  }
}

const mapStateToProps = state => {
  return {
    localization: state.localization
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userLoggedIn: (token, account) => {
      return dispatch(loginTriggerAction(token, account));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

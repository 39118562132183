import React from 'react';
import i18n from '@launchpad/i18n';
import { Trans } from 'react-i18next';
import { AppIcon } from '../../index';
import LoadingHelper from '../../util/LoadingHelper';
import CardModel from '../../modules/card/models/CardModel';
import AuthService from '../../logic/services/AuthService';

class LockCardButton extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  _changeCardLockStatus(status: any) {
    const { card } = this.props;
    LoadingHelper.executeAction(
      () => {
        return new CardModel().freezePaymentDevice(card.id, status);
      },
      {
        successMessage: status
          ? i18n.t('Your card is locked now')
          : i18n.t('Your card is active now'),
        loadingMessage: status
          ? i18n.t('Locking card...')
          : i18n.t('Unlocking card...'),
        failureMessage: i18n.t('An error ocurred, please try again later'),
        manualClose: true,
        renderIcon: (state: string, params: any) => {
          if (state === 'loading') return null;
          return status ? (
            <AppIcon name="fa.lock" style={{ fontSize: 150 }} />
          ) : (
            <AppIcon name="fa.unlock-alt" style={{ fontSize: 150 }} />
          );
        },
        beforeClose: () => {
          AuthService.refreshAuth();
        }
      }
    );
  }

  render() {
    const { card } = this.props;
    let lockElement = null as any;

    if (!card.isLive) return null;

    if (card.locked) {
      lockElement = (
        <div
          onClick={() => this._changeCardLockStatus(false)}
          className="send-load-btn-wrapper"
        >
          <div className="send-load-icon-wrapper">
            <AppIcon name="fa.unlock-alt" style={{ fontSize: 24 }} />
          </div>
          <Trans>Unlock card</Trans>
        </div>
      );
    } else if (card.status === 'ok') {
      lockElement = (
        <div
          onClick={() => this._changeCardLockStatus(true)}
          className="send-load-btn-wrapper"
        >
          <div className="send-load-icon-wrapper">
            <AppIcon name="fa.lock" style={{ fontSize: 24 }} />
          </div>
          <Trans>Lock Card</Trans>
        </div>
      );
    }

    return <div className="button-wrapper">{lockElement}</div>;
  }
}

export default LockCardButton;

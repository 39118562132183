import React from 'react';
import { LPOverridable } from '@launchpad/components';
import AppCard from '../../components/elements/AppCard';
import DashboardHeader from './components/DasboardHeader';
import LatestTransactions from './components/LatestTransactions';
import LatestNotifications from './components/LatestNotifications';
import LatestAuthorisations from './components/LatestAuthorisations';
import { OverrideService } from '@launchpad/logic/services';

const Dashboard: React.FC = () => {
  return (
    <div className="appContainer">
      <DashboardHeader />
      <div className="container">
        <div className="row">
          <div
            className={
              OverrideService.getConfig().rdx ? `col-xl-4` : 'col-xl-6'
            }
          >
            <AppCard name="exchange" title="Latest Transactions">
              <LatestTransactions />
            </AppCard>
          </div>
          <div
            className={
              OverrideService.getConfig().rdx ? `col-xl-4` : 'col-xl-6'
            }
          >
            <AppCard name="comment-o" title="New Notifications">
              <LatestNotifications />
            </AppCard>
          </div>
          {OverrideService.getConfig().rdx && (
            <div className="col-xl-4">
              <AppCard name="edit" title="New Authorisations">
                <LatestAuthorisations />
              </AppCard>
            </div>
          )}
        </div>
        <LPOverridable name="dashboard.custom.content">
          <div />
        </LPOverridable>
      </div>
    </div>
  );
};

export default Dashboard;

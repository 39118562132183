import overrides from './overrides';
import styles from './styles';
import config from './config';
import routes from './routes';
import sagas from './sagas';
import reducers from './reducers';
import navigation from './routes/navigation';

const configuration = {
  overrides,
  styles,
  config,
  routes,
  CustomNavigation: navigation,
  sagas,
  reducers
};

export default configuration;

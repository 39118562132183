import React from 'react';
import ModalDialog from '../../../components/modal/ModalDialog';
import ModalService from '../../../logic/services/ModalService';
import NumberHelper from '../../../util/NumberHelper';
import { AppIcon } from '../../../index';
import { Trans } from 'react-i18next';

export default class FxInfoModal extends React.PureComponent {
  /**
   * Constructor
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    // Initial state
    this.state = {};
  }

  render() {
    const { transaction } = this.props;

    const transactionFees = transaction.fxFee;

    return (
      <ModalDialog id="FX_INFO" show>
        <div className="transaction-modal">
          <div className="close-btn">
            <AppIcon
              name="fa.times"
              style={{
                fontSize: 24,
                cursor: 'pointer'
              }}
              onClick={() => ModalService.hideModal('FX_INFO')}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              minHeight: 550,
              width: '100%',
              marginTop: 10
            }}
          >
            <div className="section-header">
              <h5>
                <Trans>Conversion Summary</Trans>
              </h5>
            </div>
            <div
              style={{
                width: '100%',
                padding: 20,
                textAlign: 'center'
              }}
            >
              <h6 className="text-center" style={{ fontWeight: 'bold' }}>
                <Trans>Your Card Currency Amount</Trans>
              </h6>
              <h2
                className="text-center"
                style={{ fontWeight: 'bold', padding: '15px 0' }}
              >
                {NumberHelper.currency(
                  transactionFees.destinationAmount,
                  transactionFees.destinationCurrency
                )}
              </h2>
              <p>
                <Trans>
                  Effective Transaction Conversion Rate Including All Fees*:
                </Trans>
              </p>
              <p style={{ fontWeight: 'bold' }}>
                1 {transactionFees.sourceCurrency} ={' '}
                {transactionFees.effectiveRate}{' '}
                {transactionFees.destinationCurrency}
              </p>
            </div>
            <div className="section-header">
              <h5>
                <Trans>Transaction Fees</Trans>
              </h5>
            </div>
            <div
              style={{
                textAlign: 'left',
                width: '100%',
                fontSize: 14,
                padding: '10px 0'
              }}
            >
              <span>
                <Trans>
                  Non-Euro Prepaid Card Purchases are subject to a{' '}
                  {transactionFees.bankFeePct}% fee on the Mastercard conversion
                  value for this transaction:
                </Trans>
              </span>
              <span style={{ fontWeight: 'bold' }}>
                {' '}
                {transactionFees.schemeAmount}{' '}
                {transactionFees.destinationCurrency} (1{' '}
                {transactionFees.sourceCurrency} = {transactionFees.schemeRate}{' '}
                {transactionFees.destinationCurrency})
              </span>
              <br />
              <br />
              <span style={{ paddingTop: 10 }}>
                <Trans>
                  The published European Central Bank (ECB) conversion rate for
                  this currency pair on your selected transaction date is:/
                </Trans>
              </span>
              <br />
              <span style={{ fontWeight: 'bold' }}>
                1 {transactionFees.destinationCurrency} ={' '}
                {transactionFees.ecbRate} {transactionFees.sourceCurrency}
              </span>
              <br />
              <br />
              <span style={{ paddingTop: 10 }}>
                <Trans>Fee for this transaction</Trans>:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {NumberHelper.currency(
                    transactionFees.bankFee,
                    transactionFees.destinationCurrency
                  )}
                </span>
              </span>
              <br />
              <span>
                <Trans>Mastercard Fee for this transaction</Trans>:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {NumberHelper.currency(
                    transactionFees.schemeFee,
                    transactionFees.destinationCurrency
                  )}
                </span>
              </span>
              <br />
              <br />
              <span>
                <Trans>Total Fees</Trans>:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {NumberHelper.currency(
                    transactionFees.totalFee,
                    transactionFees.destinationCurrency
                  )}
                </span>
              </span>
              <br />
              <br />
              <span style={{ paddingTop: 10 }}>
                <Trans>These fees represent a</Trans>{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {transactionFees.totalFeePct}%
                </span>{' '}
                <Trans>
                  increase on the ECB conversion value for this transaction.
                </Trans>
              </span>
            </div>
          </div>
        </div>
      </ModalDialog>
    );
  }
}

import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PublicLayout from './components/layout/PublicLayout';
import DefaultLayout from './components/layout/DefaultLayout';
import { appMountedAction } from './logic/store/AppActions';
import { refreshAuthAction } from './modules/auth/AuthActions';
import { AppRoute, AppRouter } from './components';
import Modals from './modules/modals/Modals';
import { OverrideService } from './logic/services';
import { LocalizationContext } from './logic/localization';

class App extends Component {
  componentDidMount() {
    this.props.appMounted();
    this.props.refreshAuth();

    // AuthService.poolStatusUpdate();
  }

  getInnerPaths(props) {
    // if(props.match.params && props.match.params.locale) {
    //   locale = props.match.params.locale;
    // }

    let url = props.match.path;
    url = url.replace(/\/$/, '');

    const customRoutes = OverrideService.getRoutes();

    return (
      <Switch>
        {customRoutes}
        <AppRoute
          path={`${url}/login`}
          component={PublicLayout}
          unAuthenticatedOnly
        />
        <AppRoute
          path="/forgot-password"
          component={PublicLayout}
          unAuthenticatedOnly
        />
        <AppRoute
          path="/welcome"
          component={PublicLayout}
          unAuthenticatedOnly
        />
        <AppRoute path="" component={DefaultLayout} authenticatedOnly />
      </Switch>
    );
  }

  render() {
    if (!this.props.ready) return null;

    const InnerRouting = props => this.getInnerPaths(props);

    return (
      <LocalizationContext.Provider>
        <AppRouter>
          <div className="App">
            <Switch>
              <Route path="/" component={InnerRouting} />
            </Switch>
            <div id="modalHolder" className="modal-holder">
              <Modals />
            </div>
          </div>
        </AppRouter>
      </LocalizationContext.Provider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    appMounted: () => {
      return dispatch(appMountedAction());
    },
    refreshAuth: () => {
      return dispatch(refreshAuthAction());
    }
  };
};

const mapStateToProps = state => {
  return {
    ready: state.ready
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import AppStore from '../store/AppStore';
import { changeTheme, refreshAuthAction } from '../../modules/auth/AuthActions';
import Api from '../api/Api';

const APP_THEME = 'AppTheme';

export default class Auth {
  static refreshTimeout: any;

  /**
   * Refresh auth
   */
  static async refreshAuth() {
    await AppStore.dispatch(refreshAuthAction());
  }

  static async changeAppTheme(theme: string) {
    localStorage.setItem(APP_THEME, theme);
    await AppStore.dispatch(changeTheme(theme));
  }

  static async getAppTheme() {
    return await localStorage.getItem(APP_THEME);
  }

  // static async poolStatusUpdate() {
  //   if(this.refreshTimeout) {
  //     clearTimeout(this.refreshTimeout);
  //     this.refreshTimeout = null;
  //   }

  //   try {
  //     if(AppStore.getState().auth.token) {
  //       let response = await Api.call(
  //         'member/status-update',
  //       );

  //       if(response.success) {
  //         AppStore.dispatch(statusUpdateReceivedAction(response.data));
  //       }
  //     }
  //   } catch(e) {}

  //   this.refreshTimeout = setTimeout(() => {
  //     Auth.poolStatusUpdate();
  //   }, 5000);
  // }
}

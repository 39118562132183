import React, { useEffect, useRef } from 'react';
import { Trans } from 'react-i18next';
import AuthorisationModel from './models/AuthorisationModel';
import { AppList, useSelector } from '../../index';
import AuthorisationItem from './components/AuthorisationItem';

function AuthorisationsPage() {
  const list = useRef(null);
  const modals = useSelector((state: any) => state.modals);
  const prevModalsRef = useRef<any>();

  useEffect(() => {
    prevModalsRef.current = modals;
  }, []);

  useEffect(() => {
    if (
      prevModalsRef.current &&
      prevModalsRef.current.length > 0 &&
      prevModalsRef.current[0].id === 'AUTHORISATION_DETAILS_MODAL'
    ) {
      refreshList();
    }
    // Now we update prevModalsRef.current for the next render
    prevModalsRef.current = modals;
  }, [modals]);

  function refreshList() {
    if (list) {
      (list.current as any)._reset().then(() => {
        (list.current as any)._refresh();
      });
    }
  }

  function renderAuthorisationItem(i: any) {
    return <AuthorisationItem authorisation={i} key={i.id} />;
  }

  return (
    <div className="container">
      <div style={{ paddingTop: 100 }}>
        <div
          className="profile-container"
          style={{ height: '70vh', overflow: 'auto', position: 'relative' }}
        >
          <div className="row ">
            <div className="col-lg-12 notifications-col-12">
              <div className="notifications-body">
                <AppList
                  onInit={(appListRef: any) => {
                    list.current = appListRef;
                  }}
                  model={AuthorisationModel}
                  renderWrapper={(items: React.ReactNode) => (
                    <ul style={{ padding: 0, marginBottom: 0 }}>{items}</ul>
                  )}
                  renderItem={(item: any) => renderAuthorisationItem(item)}
                  renderNoItems={() => (
                    <div className="col-sm-12 well text-center">
                      <div style={{ margin: '20px' }} className="no-items">
                        <em>
                          <Trans>No new authorisations</Trans>
                        </em>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthorisationsPage;

import React from 'react';
import { Link } from 'react-router-dom';
import { AppList } from '../../../index';
import TransactionModel from '../../statements/models/TransactionModel';
import TransactionItem from '../../statements/components/TransactionItem';
import { Trans } from 'react-i18next';

export default class LatestTransactions extends React.Component {
  _list = null;

  shouldComponentUpdate(nextProps) {
    if (this._list) {
      this._list._refresh();
      return true;
    }
    return false;
  }

  _renderTransactionItem(item) {
    return <TransactionItem key={item.id} transaction={item} type="all" />;
  }

  render() {
    return (
      <div style={{ position: 'relative', minHeight: 445, maxHeight: 450 }}>
        <ul
          style={{ padding: 0, margin: 0, maxHeight: 390, overflow: 'hidden' }}
        >
          <AppList
            onInit={list => {
              this._list = list;
            }}
            model={TransactionModel}
            params={{
              limit: 5
            }}
            renderWrapper={items => <ul style={{ padding: 0 }}>{items}</ul>}
            renderItem={item => this._renderTransactionItem(item)}
            renderNoItems={() => (
              <div className="col-sm-12 well text-center">
                <div style={{ margin: '20px' }} className="no-items">
                  <em>
                    <Trans>
                      Once you start using your card, you'll see transactions
                      here.
                    </Trans>
                  </em>
                </div>
              </div>
            )}
          />
        </ul>

        <Link to="/statements" className="go-to-inbox">
          <Trans>View all</Trans>
        </Link>
      </div>
    );
  }
}

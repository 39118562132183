import React from 'react';
import { Trans } from 'react-i18next';
import ModalService from '../../../logic/services/ModalService';
import { AppButton, AppIcon, ModalDialog } from '../../../index';

export const NAME = 'INFO_MODAL';

export default class InfoModal extends React.Component {
  onClose() {
    ModalService.hideModal(NAME);
  }

  render() {
    const { modalIcon } = this.props;

    return (
      <div>
        <ModalDialog
          show
          onClose={() => this.onClose()}
          style={{
            zIndex: 999999999999
          }}
          {...this.props}
        >
          <div className="lp-modal">
            {/* Display modal icon if provided */}
            {modalIcon && (
              <AppIcon
                name={modalIcon}
                style={{
                  fontSize: 50,
                  color: '#D44E27',
                  ...this.props.iconStyle
                }}
              />
            )}

            {/* Display inner elements provided as props */}
            {this.props.innerElements}

            {/* Display close button if modal is closeable */}
            {this.props.closeable ? (
              <AppButton
                onClick={() => this.onClose()}
                style={{ marginTop: 20 }}
              >
                <Trans>{this.props.closeLabel || 'Close'}</Trans>
              </AppButton>
            ) : null}
          </div>
        </ModalDialog>
      </div>
    );
  }
}

import React from 'react';
import { LaunchPadApp } from '@launchpad';
import merge from 'deepmerge';
import configuration from './configuration';

require('./styles/overrides.scss');

const ProsperApp = ({
  configuration: customConfig
}: {
  configuration?: any;
}) => {
  const finalConfiguration = merge(configuration ?? {}, customConfig ?? {});
  // const finalConfiguration = {
  //   ...configuration,
  //   ...(customConfig ?? {})
  // };
  return <LaunchPadApp configuration={finalConfiguration} />;
};

export default ProsperApp;

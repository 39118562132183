import React, { useEffect, useState } from 'react';
import Api from '@launchpad/logic/api/Api';
import AppIcon from '@launchpad/components/elements/icon/AppIcon';
import FXRatesItem from '@launchpad/modules/fx-rates/FXRatesItem';
import ModalDialog from '@launchpad/components/modal/ModalDialog';
import AppButton from '@launchpad/components/crud/AppButton';
import InputField from '@launchpad/components/elements/form/InputField';
import { Trans } from 'react-i18next';
import i18n from '@launchpad/i18n';
import ProsperExchangeConfirmationDialog from './ProsperExchangeConfirmationDialog';

const CustomFXRatesModal = ({ visible, onClose }) => {
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(
    false
  );
  const [baseAmount, setBaseAmount] = useState('');
  const [convertedAmount, setConvertedAmount] = useState('');
  const [baseCurrency, setBaseCurrency] = useState('PPT');
  const [convertedCurrency, setConvertedCurrency] = useState('GBP');
  const [rate, setRate] = useState('');
  const [fieldValidated, setFieldValidated] = useState(null);

  // Rerender component when props visible is changed
  useEffect(() => {
    if (visible) {
      Api.call('prosper/redeem/rate')
        .then(data => {
          setRate(data.data.PPT);
        })
        .catch(error => console.log('catch:', error));
      setFieldValidated(true);
    }
  }, [visible]);

  /**
   * Set inserted amount in state, and calculate other field amount
   * @param {number} value - inserted value
   * @param {string} name - name of field (base-amount || converted-amount)
   */
  function handleAmountChanged(value, name) {
    setFieldValidated(true);
    // Calculate value of other field
    if (name === 'base-amount') {
      setBaseAmount(value);
      if (convertedCurrency && baseCurrency) {
        const amount = calculateAmountsBasedOnCurrency(value, rate, rate);
        setConvertedAmount(amount.toFixed(2));
      }
    } else {
      setConvertedAmount(value);
      if (baseCurrency && convertedCurrency) {
        const amount = calculateAmountsBasedOnCurrency(value, rate, rate);
        setBaseAmount(amount.toFixed(2));
      }
    }
  }

  // Calculate amount based on given rate
  function calculateAmountsBasedOnCurrency(amount, baseRate, convertedRate) {
    return (convertedRate / baseRate) * amount;
  }

  // Check if all values are set and show confirmation dialog
  function _handelExchangeButtonPressed() {
    if (baseCurrency && convertedCurrency && baseAmount && convertedAmount) {
      setConfirmationModalVisible(true);
    } else {
      // Show red border where data is missing
      setFieldValidated(false);
    }
  }

  // Header text with close button
  function renderHeader() {
    return (
      <div className="fx-redeem-header">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 10,
            alignItems: 'center'
          }}
        >
          <img
            src={require('@launchpad/assets/currency-euro-dollar-exchange.svg')}
            alt="Exchange office"
            style={{ paddingRight: 10 }}
          />
          <p
            style={{
              textAlignVertical: 'center',
              fontWeight: 'bold',
              fontSize: 18
            }}
          >
            <Trans>Point conversion</Trans>
          </p>
        </div>
        <div className="close-btn">
          <AppIcon
            name="fa.times"
            style={{
              fontSize: 24,
              cursor: 'pointer'
            }}
            onClick={() => onClose()}
          />
        </div>
      </div>
    );
  }

  function renderContent() {
    return (
      <div className="fx-redeem-content">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            paddingHorizontal: 10
          }}
        >
          <div style={{ width: '45%' }} className="fx-redeem-input-holder">
            <FXRatesItem
              source={require('../../../assets/img/prosper-flag.png')}
              currency={baseCurrency}
              imageStyle={{ width: 30, height: 30 }}
              text={baseCurrency}
            />
          </div>
          <div style={{ width: '45%' }} className="fx-redeem-input-holder">
            <FXRatesItem
              currency={convertedCurrency}
              imageStyle={{ width: 30, height: 30 }}
              text={convertedCurrency}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              paddingTop: 10
            }}
          >
            <InputField
              className="input-group"
              materialProps={{
                fullWidth: true,
                variant: 'standard',
                error: !fieldValidated,
                inputProps: { min: '0', step: '1', pattern: '[0-9]*' }
              }}
              label={i18n.t('Enter amount')}
              name="base-amount"
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              value={`${baseAmount}`}
              onInputText={(value, name) => handleAmountChanged(value, name)}
            />
            <AppIcon
              name="fa.arrow-right"
              style={{
                fontSize: 15,
                width: '10%',
                alignSelf: 'center',
                padding: 10
              }}
            />
            <InputField
              className="input-group"
              materialProps={{
                fullWidth: true,
                variant: 'standard',
                error: !fieldValidated,
                inputProps: { min: '0', step: '1', pattern: '[0-9]*' }
              }}
              label={i18n.t('Enter amount')}
              name="converted-amount"
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              value={`${convertedAmount}`}
              onInputText={(value, name) => handleAmountChanged(value, name)}
            />
          </div>
        </div>
        <div style={{ padding: 15 }}>
          <FXRatesItem
            source={require('../../../assets/img/prosper-flag.png')}
            currency={convertedCurrency.currency}
            text={`${rate} PPT = ${rate} GBP`}
          />
        </div>
      </div>
    );
  }

  // Exchange button at the bottom
  function renderFooter() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <AppButton
          type="submit"
          onClick={() => _handelExchangeButtonPressed()}
          style={{ width: 500 }}
        >
          <Trans>Redeem</Trans>
        </AppButton>
      </div>
    );
  }

  return (
    <div>
      <ModalDialog show={visible} onClose={() => onClose()}>
        <div className="fx-redeem-holder">
          {!confirmationModalVisible ? (
            <div>
              {renderHeader()}
              <div style={{ display: 'flex', flex: 1, width: '100%' }}>
                {renderContent()}
              </div>
              {renderFooter()}
            </div>
          ) : (
            <ProsperExchangeConfirmationDialog
              amount={baseAmount}
              convertedCurrency={convertedCurrency}
              onHide={() => [setConfirmationModalVisible(false), onClose()]}
            />
          )}
        </div>
      </ModalDialog>
    </div>
  );
};

export default CustomFXRatesModal;

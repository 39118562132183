import React, { useEffect, useState } from 'react';
import { AppSpinner, AppLayoutWithCard, connect } from '@launchpad';
import ProsperButton from '@prosper/configuration/overrides/components/ProsperButton';
import Api from '@launchpad/logic/api/Api';
import { loginTriggerAction } from '@launchpad/modules/auth/AuthActions';
import i18n from '@launchpad/i18n';
import { Trans } from 'react-i18next';

const checkHash = async hash => {
  const result: any = await Api.call(`prosper/sso/verify/${hash}`);

  return result;
};

const getProfileInfoElement = profile => {
  return (
    <div>
      <ul>
        <Trans>First name</Trans>: {profile.firstName}
      </ul>
      <ul>
        <Trans>Last name</Trans>: {profile.lastName}
      </ul>
    </div>
  );
};

const getNoAccountsBottomElement = onClick => {
  return (
    <div className="center-text" style={{ maxWidth: 500 }}>
      <p>
        <Trans>
          You'll receive a notification email when your card has been
          dispatched. When you receive your card, return here to activate it!
        </Trans>
      </p>
      <ProsperButton fillblack="true" onClick={() => onClick()}>
        <Trans>Go Back</Trans>
      </ProsperButton>
    </div>
  );
};

const getAuthenticationFailedBottomElement = onClick => {
  return (
    <div className="center-text">
      <p>
        <Trans>Please try again, or contact customer support.</Trans>
      </p>
      <ProsperButton fillblack="true" onClick={() => onClick()}>
        <Trans>Go Back</Trans>
      </ProsperButton>
    </div>
  );
};

const SsoVerifyPage: React.FC<any> = props => {
  const [step, setStep] = useState('loading');
  const [userAccount, setUserAccount] = useState<any>(null);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    checkHash(props.match.params.hash).then(response => {
      setTimeout(() => {
        if (!response.success) {
          if (response.data && response.data.reason === 'nouser') {
            setStep('nouser');
          } else {
            setStep('failed');
          }
          return;
        }

        const userAccount = response.data.account;
        setUserAccount(userAccount);

        if (!userAccount.accounts || userAccount.accounts.length === 0) {
          setStep('noaccounts');
        } else {
          props.userLoggedIn(response.data.token, response.data.account);
        }
      }, 2000);
    });
  }, []);

  let topElement: any = null;
  let mainMessage = i18n.t('Verifying your session...');
  let bottomElement: any = null;

  switch (step) {
    case 'loading':
      topElement = <AppSpinner />;
      break;
    case 'failed':
      mainMessage = i18n.t('Authentication failed!');
      bottomElement = getAuthenticationFailedBottomElement(() =>
        props.history.replace('/welcome')
      );
      break;

    case 'noaccounts':
      if (userAccount) {
        mainMessage = `${i18n.t('Hello')} ${userAccount.profile.fullName}`;
        bottomElement = getNoAccountsBottomElement(() =>
          props.history.replace('/welcome')
        );
      }
      break;

    case 'nouser':
      mainMessage = i18n.t('Hello');
      bottomElement = getNoAccountsBottomElement(() =>
        props.history.replace('/welcome')
      );
      break;

    case 'success':
      if (userAccount) {
        mainMessage = `${i18n.t('Hello')} ${userAccount.profile.fullName}`;
        bottomElement = getProfileInfoElement(userAccount.profile);
      }
      break;
  }

  return (
    <AppLayoutWithCard>
      {topElement}
      <h1 className="text-center">{i18n.t(mainMessage)}</h1>
      {bottomElement}
    </AppLayoutWithCard>
  );
};

const mapDispatchToProps = (
  dispatch: (arg0: { type: string; payload: { token: any } }) => void
) => {
  return {
    userLoggedIn: (token: any, profile: any) => {
      return dispatch(loginTriggerAction(token, profile));
    }
  };
};

export default connect(null, mapDispatchToProps)(SsoVerifyPage);

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

interface ProsperButtonProps {
  isLoading?: boolean;
  customstyle?: any;
  fillblack?: any;
  onClick?: () => void;
}

export default class ProsperButton extends React.Component<ProsperButtonProps> {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  /**
   * Get button or spinner
   */
  _getButtonContent() {
    if (this.props.isLoading) {
      return <CircularProgress className="circle-progress" size={20} />;
    }

    return this.props.children;
  }

  /**
   * Render
   */
  render() {
    let buttonClass = 'black-btn';

    if (this.props.fillblack as any) {
      buttonClass += ' fill-black-btn';
    }
    return (
      <Button
        variant="contained"
        className={buttonClass}
        style={this.props.customstyle}
        {...this.props}
      >
        {this._getButtonContent()}
      </Button>
    );
  }
}

/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { LockCardButton } from '@launchpad';
import OverrideService from '@launchpad/logic/services/overrides/OverrideService';
import { Trans } from 'react-i18next';
import RotateCard from '@launchpad/components/crud/RotateCard';
import ProsperButton from './components/ProsperButton';
import ProsperFooter from './components/ProsperFooter';
import ProsperWelcomePage from './modules/auth/ProsperWelcomePage';
import BottomShapeForLoginPage from './elements/BottomShapeForLoginPage';
import ProsperBalance from './components/ProsperBalance';
import TextOverrides from './text';
import FxNotificationsReports from './components/FxNotificationsReports';
import config from '../config';

const overrides = {
  text: TextOverrides,
  ui: {
    button: ProsperButton
  },
  welcome: {
    page: {
      content: (props: any) => <ProsperWelcomePage {...props} />
    }
  },
  login: {
    page: {
      bottom: () => (
        <BottomShapeForLoginPage
          lineColor={OverrideService.getThisOrOverridenColors().main_color}
          circleColor={OverrideService.getThisOrOverridenColors().main_color}
          height="150px"
        />
      ),
      bottomText: () => null
    }
  },
  elements: {
    button: (props: any) => (
      <ProsperButton fillblack="true" {...props}>
        {props.children}
      </ProsperButton>
    )
  },
  dashboard: {
    bottom: () => null,
    additionalBalance: props => <ProsperBalance {...props} />
  },

  card: {
    action: {
      buttons: (props: any) => <LockCardButton {...props} />,
      FxNotificationsReports: () => <FxNotificationsReports />
    },
    image: (card: any) => {
      // Render card image from BE if returned, if not render default
      const location = new URL(config.url);
      if (card.card?.imagePath && card.card?.backImagePath) {
        return (
          <RotateCard
            frontCard={location.origin + card.card?.imagePath}
            backCard={location.origin + card.card?.backImagePath}
            card={card?.card}
          />
        );
      } else if (card.card?.imagePath && !card.card?.backImagePath) {
        return (
          <RotateCard
            frontCard={location.origin + card.card?.imagePath}
            backCard={require('../../assets/img/Prosper-Card-Back.png')}
            card={card?.card}
          />
        );
      } else if (!card.card?.imagePath && card.card?.backImagePath) {
        return (
          <RotateCard
            frontCard={require('../../assets/img/Prosper-Card-Front.png')}
            backCard={location.origin + card.card?.backImagePath}
            card={card?.card}
          />
        );
      } else if (!card.card?.imagePath && !card.card?.backImagePath) {
        return (
          <RotateCard
            frontCard={require('../../assets/img/Prosper-Card-Front.png')}
            backCard={require('../../assets/img/Prosper-Card-Back.png')}
            card={card?.card}
          />
        );
      }
      return card.children;
    },
    page: {
      linkTC: () => (
        <a
          href="https://www.prosper2.co.uk/cardholder-terms-and-conditions/"
          target="_blank"
          style={{ color: 'inherit' }}
        >
          <span>
            <Trans>Terms & Conditions</Trans>
          </span>
        </a>
      ),
      autoTopUp: () => null,
      disableInternet: () => null,
      lockModalNumber: () => (
        <p style={{ fontSize: 22 }}>
          <strong>(0203 925 2759)</strong>
        </p>
      )
    }
  },
  footer: {
    content: () => <ProsperFooter />
  },
  transaction: {
    details: {
      modal: {
        bottom: () => null
      }
    },
    merchant: {
      image: () => null
    }
  },
  notificationsPage: {
    page: {
      header: () => null
    }
  },
  'unread-circle-color': '#887649',
  'show-download-statements-btn': true,
  'hide-theme-switcher': true,
  'hide-language-switcher': true,
  'expires-right': '300px',
  'expires-bottom': '40px'
};

export default overrides;

const navigation = [
  {
    label: 'Dashboard',
    icon: 'tachometer',
    path: '/'
  },
  {
    label: 'Cards',
    icon: 'credit-card',
    path: '/card'
  },
  {
    label: 'Statements',
    icon: 'line-chart',
    path: '/statements'
  }
];

export default navigation;

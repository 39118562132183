import React from 'react';
import { Line, Circle } from 'rc-progress';
import OverrideService from '@launchpad/logic/services/overrides/OverrideService';

export default class ProsperProgressBar extends React.Component<any, any> {
  state = {};

  _getProgressContent() {
    let strokeColor = 'transparent';
    if (this.props.percent !== 0) {
      strokeColor = OverrideService.getThisOrOverridenColors().main_color;
    }
    if (this.props.circle) {
      return (
        <Circle
          percent={this.props.percent}
          trailWidth={2}
          trailColor={OverrideService.getThisOrOverridenColors().gray_color}
          strokeWidth={2}
          strokeColor={strokeColor}
        />
      );
    }

    return (
      <Line
        percent={this.props.percent}
        trailWidth={2}
        trailColor={OverrideService.getThisOrOverridenColors().gray_color}
        strokeWidth={2}
        strokeColor={strokeColor}
      />
    );
  }

  render() {
    return (
      <div style={{ width: '100%', maxWidth: 600 }}>
        {this._getProgressContent()}
      </div>
    );
  }
}

import React from 'react';
import i18n from '@launchpad/i18n';
import ModalService from '@launchpad/logic/services/ModalService';
import { fullDate } from '../../../util/DateHelper';

const AuthorisationItem: React.FC<any> = props => {
  const { authorisation } = props;

  if (!authorisation) {
    return null;
  }

  return (
    <li
      className="notificationItem"
      style={{ cursor: 'pointer' }}
      onClick={() =>
        ModalService.showModal('AUTHORISATION_DETAILS_MODAL', {
          authorisation
        })
      }
    >
      <div
        style={{
          position: 'relative',
          flex: 1,
          paddingLeft: 20,
          paddingBottom: 20
        }}
      >
        <p>{i18n.t(authorisation.title)}</p>
        <p>
          {i18n.t(
            authorisation?.['3ds_authentication_request']?.merchant_name ||
              authorisation.authorization_code
          )}
        </p>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0
          }}
        >
          <span style={{ fontSize: 12 }}>
            {fullDate(authorisation.created_at)}
          </span>
        </div>
      </div>
    </li>
  );
};

export default AuthorisationItem;

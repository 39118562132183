import React from 'react';
import DatePicker from 'react-datepicker';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import ModalDialog from '../../../components/modal/ModalDialog';
import { OverrideService } from '../../../logic/services';
import TransactionModel from '../models/TransactionModel';
import 'react-datepicker/dist/react-datepicker.css';
import { AppIcon } from '../../../index';
import { Trans } from 'react-i18next';
import i18n from '@launchpad/i18n';

class DownloadStatementsModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedFrom: moment(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      ).format('YYYY-MM-DD'),
      selectedTo: moment(new Date()).format('YYYY-MM-DD'),
      fileType: 'pdf',
      cardToken: null,
      currency: null,
      loading: false
    };
    this.handleChangeFrom = this.handleChangeFrom.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
  }

  componentDidMount() {
    this.setInitDates();
  }

  handleChangeFrom(dateFrom) {
    this.setState({
      selectedFrom: dateFrom
        ? moment(dateFrom).format('YYYY-MM-DD')
        : moment(
            new Date(new Date().getFullYear(), new Date().getMonth(), 1)
          ).format('YYYY-MM-DD')
    });
  }

  handleChangeTo(dateTo) {
    this.setState({
      selectedTo: dateTo
        ? moment(dateTo).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD')
    });
  }

  setInitDates() {
    this.setState({
      selectedFrom: moment(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      ).format('YYYY-MM-DD'),
      selectedTo: moment(new Date()).format('YYYY-MM-DD'),
      fileType: 'pdf',
      currency: null,
      cardToken: null
    });
  }

  async downloadStatamentsFile() {
    this.setState({
      loading: true
    });
    try {
      const downloadUrl = await new TransactionModel().getDownloadUrl(
        // 'stataments',
        // this.props.accountId,
        this.state.fileType,

        {
          ...this.props.filter,
          startDate: this.state.selectedFrom,
          endDate: this.state.selectedTo,
          cardToken: this.state.cardToken,
          currency: this.state.currency
        }
      );

      if (downloadUrl) {
        this.setState(
          {
            loading: false
          },
          () => {
            window.location = downloadUrl;

            setTimeout(() => {
              this.props.closeDownloadModal();
              this.setInitDates();
            }, 50);
          }
        );
      }
    } catch (err) {
      console.log(err);
      this.setState({
        loading: false
      });
    }
  }

  selectCurrency(item) {
    if (this.state.currency !== item) {
      this.setState({
        currency: item
      });
    } else {
      this.setState({
        currency: null
      });
    }
  }

  renderSelectFileButton(file) {
    return (
      <div
        className="d-flex flex-row align-items-center justify-content-center p-2"
        style={{
          cursor: 'pointer'
        }}
        onClick={() => this.setState({ fileType: file })}
      >
        {this.state.fileType === file ? (
          <AppIcon
            name="fa.check-square-o"
            style={{
              color: OverrideService.getThisOrOverridenColors().main_color,
              fontSize: 20
            }}
          />
        ) : (
          <AppIcon
            name="fa.square-o"
            style={{
              color: OverrideService.getThisOrOverridenColors().main_color,
              fontSize: 20
            }}
          />
        )}
        <p
          style={{
            fontSize: 16,
            textAlign: 'center',
            paddingLeft: 5,
            color:
              this.state.fileType === file
                ? OverrideService.getThisOrOverridenColors().main_color
                : OverrideService.getThisOrOverridenColors().gray_color,
            textTransform: 'uppercase'
          }}
        >
          {file}
        </p>
      </div>
    );
  }

  renderSelectAmountCurrencyButton(curr) {
    return (
      <div
        className="d-flex flex-row align-items-center justify-content-center p-2"
        style={{
          cursor: 'pointer'
        }}
        onClick={() => this.selectCurrency(curr)}
      >
        {this.state.currency === curr ? (
          <AppIcon
            name="fa.check-square-o"
            style={{
              color: OverrideService.getThisOrOverridenColors().main_color,
              fontSize: 20
            }}
          />
        ) : (
          <AppIcon
            name="fa.square-o"
            style={{
              color: OverrideService.getThisOrOverridenColors().main_color,
              fontSize: 20
            }}
          />
        )}
        <p
          style={{
            fontSize: 16,
            textAlign: 'center',
            paddingLeft: 5,
            color:
              this.state.currency === curr
                ? OverrideService.getThisOrOverridenColors().main_color
                : OverrideService.getThisOrOverridenColors().gray_color,
            textTransform: 'uppercase'
          }}
        >
          {curr}
        </p>
      </div>
    );
  }

  render() {
    return (
      <ModalDialog
        show={this.props.visible}
        onClose={() => this.props.closeDownloadModal()}
      >
        <div className="lock-card-modal">
          <div className="d-flex flex-row align-items-center justify-content-center w-100 position-relative">
            <h4
              style={{
                color: OverrideService.getThisOrOverridenColors().main_color
              }}
            >
              <Trans>Download statement</Trans>
            </h4>

            <AppIcon
              name="fa.times"
              style={{
                fontSize: 24,
                cursor: 'pointer',
                position: 'absolute',
                right: 0,
                zIndex: 999999
              }}
              onClick={() => this.props.closeDownloadModal()}
            />
          </div>
          {this.state.loading ? (
            <div
              className="d-flex w-100 h-100 align-items-center justify-content-center"
              style={{ flex: 1 }}
            >
              <CircularProgress className="circle-progress" size={20} />
            </div>
          ) : (
            <div
              className="d-flex flex-column align-items-center justify-content-center pt-3 text-center"
              style={{ flex: 1 }}
            >
              <p>
                <Trans>Select a period</Trans>
              </p>

              <div className="d-flex flex-row align-items-center justify-content-between w-100 position-relative flex-column flex-md-row">
                <div className="datePickerHolder">
                  <p className="calendarFrom statement-date-text">
                    <Trans>From</Trans>
                  </p>
                  <DatePicker
                    selected={Date.parse(this.state.selectedFrom)}
                    onChange={this.handleChangeFrom}
                    className="dateFrom statement-date"
                    dateFormat="dd/MM/yyyy"
                    // isClearable={!!this.state.selectedFrom}
                    maxDate={new Date()}
                    showYearDropdown
                    showMonthDropdown
                  />
                </div>
                <div className="datePickerHolder">
                  <p className="calendarTo statement-date-text">
                    <Trans>To</Trans>
                  </p>
                  <DatePicker
                    ref={datepickerTo => {
                      this.datepickerTo = datepickerTo;
                    }}
                    selected={Date.parse(this.state.selectedTo)}
                    onChange={this.handleChangeTo}
                    className="dateTo statement-date"
                    dateFormat="dd/MM/yyyy"
                    // isClearable={!!this.state.selectedTo}
                    maxDate={new Date()}
                    showYearDropdown
                    showMonthDropdown
                  />
                </div>
              </div>
              <div>
                <p>
                  <Trans>Select a file type</Trans>
                </p>
                <div className="d-flex flex-row align-items-center justify-content-between py-2">
                  {this.renderSelectFileButton('pdf')}
                  {this.renderSelectFileButton('csv')}
                </div>
              </div>

              <div>
                <p>
                  <Trans>Select a currency</Trans>
                </p>
                <div className="d-flex flex-row align-items-center justify-content-between py-2">
                  {this.renderSelectAmountCurrencyButton('GBP')}
                  {this.renderSelectAmountCurrencyButton('EUR')}
                  {this.renderSelectAmountCurrencyButton('USD')}
                </div>
              </div>

              <div
                className="points-redeem d-flex align-items-center justify-content-center text-center my-3 px-5"
                onClick={() => this.downloadStatamentsFile()}
              >
                <AppIcon
                  name="fa.download"
                  style={{
                    fontSize: 20,
                    marginRight: 5
                  }}
                />
                <p
                  className="progress-text"
                  style={{ textTransform: 'uppercase' }}
                >
                  <Trans>Download</Trans>
                </p>
              </div>
            </div>
          )}
        </div>
      </ModalDialog>
    );
  }
}

export default DownloadStatementsModal;

import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppIcon, LPOverridable } from '../../../index';

export default class AppFooter extends React.PureComponent<any, any> {
  render() {
    return (
      <div className="app-footer">
        <div className="container">
          <LPOverridable name="footer.content">
            <div className="row">
              <div className="col-md-3 footer-column">
                <h3>
                  <Trans>LAUNCHPAD</Trans>
                </h3>
                <Link to="/" className="footer-link">
                  <Trans>About Launchpad</Trans>
                </Link>
                <Link to="/" className="footer-link">
                  <Trans>Current projects</Trans>
                </Link>
                <Link to="/" className="footer-link">
                  <Trans>How it works</Trans>
                </Link>
                <Link to="/" className="footer-link">
                  <Trans>Partners</Trans>
                </Link>
              </div>
              <div className="col-md-3 footer-column">
                <h3>
                  <Trans>INFORMATION</Trans>
                </h3>
                <Link to="/terms-conditions" className="footer-link">
                  <Trans>T&Cs</Trans>
                </Link>
                <Link to="/faq" className="footer-link">
                  <Trans>FAQ</Trans>
                </Link>
                <Link to="/" className="footer-link">
                  <Trans>Contact us</Trans>
                </Link>
                <Link to="/" className="footer-link">
                  <Trans>Help</Trans>
                </Link>
                <Link to="/" className="footer-link">
                  <Trans>Press</Trans>
                </Link>
              </div>
              <div className="col-md-3 footer-column">
                <h3>
                  <Trans>CONTACT</Trans>
                </h3>
                <p className="footer-link">
                  <Trans>Contact us by email</Trans>
                </p>
                <a className="footer-link" href="mailto:info@launchpad.com">
                  info@launchpad.com
                </a>
                <a className="footer-link" href="tel:012345678">
                  Tel 012345678
                </a>
              </div>
              <div className="col-md-3 footer-column last-column">
                <h3>
                  <Trans>CONNECT WITH US</Trans>
                </h3>
                <div>
                  <a href="/">
                    <AppIcon
                      name="fa.facebook-square"
                      style={{ fontSize: 40 }}
                    />
                  </a>
                  <a href="/">
                    <AppIcon name="fa.twitter" style={{ fontSize: 40 }} />
                  </a>
                </div>
                <div className="pannovate-logo">
                  <p>
                    <Trans>powered by</Trans>
                  </p>
                  <img
                    src={require('../../../assets/pannovate_logo_black.svg')}
                    alt="pannovate-logo"
                  />
                </div>
              </div>

              <div className="last-line">
                <p>
                  © Launchpad {new Date().getFullYear()}{' '}
                  <Trans>copyright</Trans>
                </p>
              </div>
            </div>
          </LPOverridable>
        </div>
      </div>
    );
  }
}

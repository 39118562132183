import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Api from '@launchpad/logic/api/Api';
import FXRatesItem from '@launchpad/modules/fx-rates/FXRatesItem';
import AppButton from '@launchpad/components/crud/AppButton';
import { getCurrencySymbol } from '@launchpad/util/CurrencyHelper';
import Auth from '@launchpad/logic/services/AuthService';
import i18n from '@launchpad/i18n';
import { Trans } from 'react-i18next';

const ProsperExchangeConfirmationDialog = ({
  amount,
  convertedCurrency,
  onHide
}) => {
  const [loading, setLoading] = useState(true);
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationSuccess, setConfirmationSuccess] = useState(false);

  async function fetchConvertedAmount() {
    const result = await Api.call('prosper/redeem/calculate', {
      points: amount
    });
    const returndAmount = result ? result.data.GBP : 0;
    setLoading(false);
    return setConvertedAmount(returndAmount);
  }

  useEffect(() => {
    // Call api to get converted value
    fetchConvertedAmount();
  }, []);

  async function handleSubmitButton() {
    setLoading(true);
    const result = await Api.call('prosper/redeem/exchange', {
      points: amount
    }).catch(e => {
      setLoading(false);
      setConfirmationSuccess(false);
      return setConfirmationMessage(e.message);
    });
    setLoading(false);
    if (result) {
      if (result.success) {
        setConfirmationSuccess(true);
        Auth.refreshAuth();
        return setConfirmationMessage(
          'You have successfully exchanged points.'
        );
      }
      setConfirmationSuccess(false);
      return setConfirmationMessage(result.message);
    }
    return null;
  }

  if (confirmationMessage) {
    return (
      <div className="exchange-confirmation-message-container">
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column-reverse',
            alignItems: 'center',
            paddingBottom: 10
          }}
        >
          {/* Show icon based on result.success */}
          {confirmationSuccess ? (
            <img
              src={require('@launchpad/assets/check-circle-1.svg')}
              alt="Success"
              style={{ width: 50, height: 50 }}
            />
          ) : (
            <img
              src={require('@launchpad/assets/alert-circle.svg')}
              alt="Failed"
              style={{ width: 50, height: 50 }}
            />
          )}
        </div>
        <div
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <p style={{ fontSize: 20, textAlign: 'center' }}>
            {i18n.t(confirmationMessage)}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            flexDirection: 'column-reverse',
            width: '100%'
          }}
        >
          <AppButton type="submit" onClick={() => onHide()}>
            <Trans>OK</Trans>
          </AppButton>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress className="circle-progress" size={20} />
      </div>
    );
  }

  return (
    <div className="exchange-confirmation-holder">
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div style={{ justifyContent: 'space-evenly', flex: 1 }}>
          <p
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              paddingBottom: 20
            }}
          >
            <Trans>Are you sure you want to redeem</Trans>
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <FXRatesItem
              source={require('../../../assets/img/prosper-flag.png')}
              text={parseFloat(amount).toFixed(2)}
              textStyle={{ fontWeight: 'bold' }}
            />
            <p
              style={{
                fontSize: 20,
                paddingHorizontal: 20
              }}
            >
              <Trans>To</Trans>
            </p>
            <FXRatesItem
              {...convertedCurrency}
              text={
                getCurrencySymbol(convertedCurrency.currency) +
                parseFloat(convertedAmount).toFixed(2)
              }
              textStyle={{ fontWeight: 'bold' }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <div style={{ flex: 1, marginRight: 10 }}>
          <AppButton type="submit" onClick={() => onHide()}>
            <Trans>NO</Trans>
          </AppButton>
        </div>
        <div style={{ flex: 1 }}>
          <AppButton type="submit" onClick={() => handleSubmitButton()}>
            <Trans>Yes</Trans>
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default ProsperExchangeConfirmationDialog;

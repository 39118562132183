import React, { Component } from 'react';
import OverrideService from '@launchpad/logic/services/overrides/OverrideService';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import LanguageSwitcher from '@launchpad/components/layout/components/LanguageSwitcher';
import TopWhiteShapeForLoginPage from '../../elements/TopWhiteShapeForLoginPage';
import BottomShapeForLoginPage from '../../elements/BottomShapeForLoginPage';
import ProsperButton from '../../components/ProsperButton';

class ProsperWelcomePage extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      language: ''
    };
  }

  componentDidMount(): void {
    const localTheme = localStorage.getItem('AppTheme');

    document.documentElement.className = '';
    document.documentElement.classList.add(`theme-${localTheme}`);
  }

  componentDidUpdate(nextProps: any, prevProps: any) {
    if (
      nextProps.localization.locale !== this.props.localization.locale &&
      this.props.localization.locale
    ) {
      this.updateLanguage(nextProps.localization.locale);
    }
  }

  updateLanguage(locale: any) {
    this.setState({
      language: locale
    });
  }

  goToLoginPage() {
    window.location.href =
      process.env.REACT_APP_LOGIN_URL || 'https://member.prosper2.co.uk/';
    // process.env.REACT_APP_LOGIN_URL || 'https://pactuat.azurewebsites.net/'; // STAGE URL
  }

  render() {
    return (
      <div className="login-overlay-container" key={this.state.language}>
        <img
          className="logo"
          src={
            OverrideService.getStyles().logo ||
            require('../../../../assets/img/Prosper2-logo.png')
          }
          alt="logo"
        />

        <TopWhiteShapeForLoginPage />
        <div className="center-element">
          {OverrideService.getOverrides()['hide-language-switcher']
            ? null
            : this.props.localization.languages && (
                <div className="auth-language-holder">
                  <LanguageSwitcher showCurrentLanguageName />
                </div>
              )}
          <h1>
            <Trans>Welcome to</Trans>
            <br />
            {OverrideService.getStyles().APP_NAME}
          </h1>
          <h3>
            {OverrideService.getStyles().APP_NAME}{' '}
            <Trans>
              delivers unique business benefits designed to grow business,
              generate loyalty and drive efficiencies.
            </Trans>
          </h3>
          <ProsperButton onClick={() => this.goToLoginPage()}>
            <Trans>SIGN IN</Trans>
          </ProsperButton>
        </div>
        <BottomShapeForLoginPage
          lineColor={OverrideService.getThisOrOverridenColors().main_color}
          circleColor={OverrideService.getThisOrOverridenColors().main_color}
          height="200px"
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    localization: state.localization
  };
};

export default connect(mapStateToProps)(ProsperWelcomePage);

import OverrideService from '@launchpad/logic/services/overrides/OverrideService';
import React from 'react';

export default class TopWhiteShapeForLoginPage extends React.PureComponent<
  any,
  any
> {
  render() {
    return (
      <div className="top-element">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill={OverrideService.getThisOrOverridenColors().main_color}
            fillOpacity="1"
            d="M0,64L720,96L1440,256L1440,0L720,0L0,0Z"
          />
        </svg>
      </div>
    );
  }
}

import { all, takeEvery, fork, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  MEMBER_LOGIN_TRIGGER,
  MEMBER_LOGOUT_TRIGGER,
  MEMBER_LOGOUT_DONE,
  MEMBER_AUTH_REFRESH,
  MEMBER_TOKEN_LOADED,
  MEMBER_AUTH_LOADED,
  MEMBER_AUTH_REDIRECTED,
  CHANGE_THEME
} from './AuthActions';
import Api from '../../logic/api/Api';
import { history } from '../../logic/store/AppStore';
import AuthService from '@launchpad/logic/services/AuthService';

/**
 * Logout user
 *
 * @param {Object} action
 */
function* loginMember(action) {
  try {
    window.localStorage.setItem('AUTH_TOKEN', action.payload.token);

    yield put({ type: MEMBER_AUTH_LOADED, payload: action.payload.account });

    history.push('/');

    yield put({ type: MEMBER_AUTH_REDIRECTED });
  } catch (e) {}
}

function* logoutMember(action) {
  try {
    window.localStorage.removeItem('AUTH_TOKEN', action.payload.token);
  } catch (e) {}

  yield put({ type: MEMBER_LOGOUT_DONE });
}

function* refreshAuth(action) {
  try {
    const token = window.localStorage.getItem('AUTH_TOKEN');
    const theme = window.localStorage.getItem('AppTheme');

    if (!theme) {
      AuthService.changeAppTheme('light');
    }

    yield put({ type: CHANGE_THEME, payload: theme });

    if (token) {
      yield put({ type: MEMBER_TOKEN_LOADED, payload: token });
      const result = yield Api.call('auth/me');

      if (result.success) {
        yield put({ type: MEMBER_AUTH_LOADED, payload: result.data });
      }
    }
  } catch (e) {}
}

export function* watchMemberLogin() {
  yield takeEvery(MEMBER_LOGIN_TRIGGER, loginMember);
}

export function* watchMemberLogout() {
  yield takeEvery(MEMBER_LOGOUT_TRIGGER, logoutMember);
}

export function* watchRefreshAuth() {
  yield takeEvery(MEMBER_AUTH_REFRESH, refreshAuth);
}

export default function* authSagas() {
  // yield all([
  yield fork(watchMemberLogin);
  yield fork(watchMemberLogout);
  yield fork(watchRefreshAuth);
  // ]);
}

import React from 'react';
import { OverrideService } from '@launchpad/logic/services';
import SideMenuItem from './SideMenuItem';
// import { LPOverridable } from '../../../index';

export default class AppSideBar extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      navigation: OverrideService.getCustomNavigation() || this.getNavigation(),
      mobileSideState: props.isOpen
    };
  }

  componentDidMount() {
    this.setState({ mobileSideState: this.props.isOpen });
  }

  static getDerivedStateFromProps(props: { isOpen: any }, state: any) {
    if (props.isOpen !== state.mobileSideState) {
      return {
        mobileSideState: props.isOpen
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  getNavigation() {
    return [
      {
        label: 'Dashboard',
        icon: 'tachometer',
        path: '/'
      },
      {
        label: 'Cards',
        icon: 'credit-card',
        path: '/card'
      },
      {
        label: 'Statements',
        icon: 'line-chart',
        path: '/statements'
      },
      {
        label: 'Profile',
        icon: 'user-circle-o',
        path: '/profile'
      }
    ];
  }

  render() {
    const items = [] as any;
    this.state.navigation.forEach((item: any, index: any) => {
      const key = `side-menu-item-${index}`;
      items.push(<SideMenuItem {...item} key={key} />);
    });

    const { mobileSideState } = this.state;
    let mobileClass = '';

    if (mobileSideState) {
      mobileClass = 'mobile-side-bar';
    }

    return (
      <div className={`side-bar ${mobileClass}`}>
        {mobileSideState ? (
          <div
            className="phone-overlay"
            onClick={() => this.props.onPhoneOverlayClick()}
          />
        ) : null}
        {/* <LPOverridable name={'sidemenu.logo'}> */}
        <div className="dash-logo">
          <img
            src={
              OverrideService.getStyles().logo ||
              require('../../../assets/Launchpad_logo.png')
            }
            alt="logo"
          />
        </div>
        {/* </LPOverridable> */}
        <ul className="sideMenuList">{items}</ul>
      </div>
    );
  }
}

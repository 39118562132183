export const MEMBER_LOGIN_TRIGGER = 'MEMBER_LOGIN_TRIGGER';
export const MEMBER_TOKEN_LOADED = 'MEMBER_TOKEN_LOADED';
export const MEMBER_LOGOUT_TRIGGER = 'MEMBER_LOGOUT_TRIGGER';
export const MEMBER_LOGOUT_DONE = 'MEMBER_LOGOUT_DONE';
export const MEMBER_AUTH_REFRESH = 'MEMBER_AUTH_REFRESH';
export const MEMBER_AUTH_LOADED = 'MEMBER_AUTH_LOADED';
export const MEMBER_AUTH_REDIRECTED = 'MEMBER_AUTH_REDIRECTED';
export const MEMBER_TOKEN_PRELOAD = 'MEMBER_TOKEN_PRELOAD';
export const MEMBER_STATUS_UPDATE_RECEIVED = 'MEMBER_STATUS_UPDATE_RECEIVED';
export const MEMBER_AUTH_UPDATE_STORE = 'MEMBER_AUTH_UPDATE_STORE';
export const MEMBER_PROFILE_UPDATE_STORE = 'MEMBER_PROFILE_UPDATE_STORE';
export const CHANGE_THEME = 'CHANGE_THEME';

export const loginTriggerAction = (token: any, account: any) => ({
  type: MEMBER_LOGIN_TRIGGER,
  payload: {
    token,
    account
  }
});
export const logoutTriggerAction = () => ({
  type: MEMBER_LOGOUT_TRIGGER,
  payload: {
    // token
  }
});

export const refreshAuthAction = () => ({
  type: MEMBER_AUTH_REFRESH
});

export const statusUpdateReceivedAction = (statusUpdate: any) => ({
  type: MEMBER_STATUS_UPDATE_RECEIVED,
  payload: statusUpdate
});

export const updateAccountInStore = (account: any) => ({
  type: MEMBER_AUTH_UPDATE_STORE,
  payload: account
});

export const updateProfileInStoreAction = (profile: any) => ({
  type: MEMBER_PROFILE_UPDATE_STORE,
  payload: profile
});

export const changeTheme = (theme: any) => ({
  type: CHANGE_THEME,
  payload: theme
});

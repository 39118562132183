import React from 'react';
import Particles from 'react-particles-js';
import $ from 'jquery';

const BottomShapeForLoginPage = ({ circleColor, lineColor, height }) => {
  let value = 70;
  const $window = $(window);
  const windowsize = $window.width();

  if (windowsize < 500) {
    value = 20;
  }

  return (
    <div className="svg-container">
      <Particles
        className="web-particles"
        params={{
          particles: {
            number: {
              value
            },
            color: {
              value: circleColor
            },
            shape: {
              type: 'circle',
              stroke: {
                width: 0,
                color: circleColor
              }
            },
            opacity: {
              value: 1,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false
              }
            },
            size: {
              value: 5,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false
              }
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: lineColor,
              opacity: 1,
              width: 1
            },
            move: {
              enable: true,
              speed: 3,
              direction: 'none',
              random: true,
              straight: false,
              out_mode: 'bounce',
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
              }
            }
          },
          retina_detect: true
        }}
        width="100%"
        height={height}
      />
    </div>
  );
};

export default BottomShapeForLoginPage;

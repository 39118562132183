import React from 'react';
import { Trans } from 'react-i18next';
import ModalService from '../../../logic/services/ModalService';
import { AppButton, AppIcon, ModalDialog } from '../../../components/index';

// Define the name constant for the modal
export const NAME = 'APPROVED_TRANSACTION';

export default class ApprovedTransactionModal extends React.Component {
  onClose() {
    ModalService.hideModal(NAME);
  }

  render() {
    return (
      <div>
        <ModalDialog
          show
          onClose={() => this.onClose()}
          style={{
            zIndex: 999999999999
          }}
          {...this.props}
        >
          <div className="lp-modal">
            <AppIcon name="icon_check" style={{ fontSize: 70 }} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <h3
                style={{
                  marginTop: 20,
                  textAlign: 'center',
                  fontSize: '1.2rem',
                  marginBottom: 10
                }}
              >
                <Trans>Authentication Successful</Trans>
              </h3>
              <span style={{ textAlign: 'center', margin: '20px 0 20px 0' }}>
                <Trans>Please return to the merchant screen.</Trans>
              </span>
            </div>
            <AppButton
              type="button"
              onClick={() => this.onClose()}
              style={{ marginTop: 20 }}
            >
              <Trans>Close</Trans>
            </AppButton>
          </div>
        </ModalDialog>
      </div>
    );
  }
}

import OverrideService from '@launchpad/logic/services/overrides/OverrideService';
import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AppValidationErrors from './AppValidationErrors';

export default class AppRadioInput extends Component<any, any> {
  static defaultProps: any = { onChange: () => null, style: {} };

  constructor(props: any) {
    super(props);

    this.state = {
      value: props.value,
      id: props.id ? props.id : uuidv4()
    };
  }

  getErrors() {
    if (this.props.hideValidationErrors) return null;

    const messages: any[] = [];

    this.props.handler
      .getErrors()
      .filter((x: { field: any }) => x.field === this.props.name)
      .forEach((error: { message: any }) => messages.push(error.message));
    if (messages.length === 0) {
      return null;
    }
    return (
      <div
        className="validation-message checkbox-error"
        key={`${this.props.name}-error`}
      >
        {messages.join('<br/>')}
      </div>
    );
  }

  /**
   * Toggle checkbox value
   */
  _toggleValue() {
    this.setState(
      {
        value: this.state.value
      },
      () => {
        if (this.props.handler) {
          this.props.handler.handleInputChange(
            this.props.name,
            this.state.value
          );
          if (this.props.validateOnChange) {
            this.props.handler.validateField(this.props.name, this.state.value);
          }
        }

        this.props.onChange(this.state.value);
      }
    );
  }

  render() {
    return (
      <div>
        <div
          style={{
            ...{
              display: 'flex',
              alignItems: 'center',
              position: 'relative'
            },
            ...this.props.style
          }}
        >
          <div style={{ margin: 0, minWidth: 20, maxWidth: 20, height: 20 }}>
            <input
              type="radio"
              id={this.state.id}
              className="radio-button"
              name={this.props.name}
              checked={this.props.checked}
              onChange={() => this._toggleValue()}
            />
          </div>
          <label
            htmlFor={this.state.id}
            style={{
              cursor: 'pointer',
              color: this.props.checked
                ? OverrideService.getThisOrOverridenColors().main_color
                : OverrideService.getThisOrOverridenColors().gray_color,
              fontWeight: 'lighter',
              fontSize: this.props.fontSizeLabel || 14,
              paddingLeft: 10,
              marginBottom: 0
            }}
          >
            {this.props.labelText}
          </label>
        </div>
        <AppValidationErrors
          name={this.props.name}
          handler={this.props.handler}
        />
      </div>
    );
  }
}

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CardService from '../CardService';
import {
  AppIcon,
  AppButton,
  LPOverridable,
  OverrideService
} from '../../../index';
import CardDetails from '../components/CardDetails';
import LockCard from '../components/LockCard';
import ShowPin from '../components/ShowPin';
import ModalService from '../../../logic/services/ModalService';
import { Trans } from 'react-i18next';

class CardPage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      type: '',
      activeCard: null
    };
  }

  getActiveCardBtn(pd: { isLive: any }) {
    let activeCardBtn = null as any;
    if (!pd.isLive) {
      activeCardBtn = (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1
          }}
        >
          <AppButton
            onClick={() => ModalService.showModal('ACTIVATE_CARD', { pd })}
          >
            <Trans>Activate Card</Trans>
          </AppButton>
        </div>
      );
    }
    return activeCardBtn;
  }

  getLoadAccount(profile: any, pd: { isLive: any }) {
    return (
      <div
        onClick={() =>
          ModalService.showModal('LOAD_CARD_MODAL', { profile, pd })
        }
        className="send-load-btn-wrapper"
      >
        <div className="send-load-icon-wrapper">
          <AppIcon
            name="fa.plus"
            style={{ fontSize: 24, color: pd.isLive ? 'white' : 'black' }}
          />
        </div>
        <Trans>Load</Trans>
      </div>
    );
  }

  getActiveCardOverlay(pd: { isLive: any; locked: any }) {
    let activeCardOverlay = null as any;

    if (!pd.isLive) {
      activeCardOverlay = (
        <div className="card-not-active-overlay">
          <h3 style={{ textTransform: 'uppercase' }}>
            <Trans>Activate Card</Trans>
          </h3>
        </div>
      );
    }

    if (pd.locked) {
      activeCardOverlay = (
        <div className="card-not-active-overlay">
          <AppIcon name="fa.lock" style={{ fontSize: 100 }} />
        </div>
      );
    }
    return activeCardOverlay;
  }

  getSendMoney = (pd: { isLive: any }) => (
    <LPOverridable name="card.send-money-btn">
      <Link to="/send-money" className="send-load-btn-wrapper">
        <div className="send-load-icon-wrapper">
          <AppIcon
            name="fa.refresh"
            style={{
              fontSize: 24,
              color: pd.isLive ? 'white' : 'black'
            }}
          />
        </div>
        <Trans>Send Money</Trans>
      </Link>
    </LPOverridable>
  );

  changeContent(pd: any) {
    // const paymentDevice = CardService.getDefaultCard(this.props.paymentDevices);
    const paymentDevice = this.props.paymentDevices.find(
      (x: { id: any }) => x.id === this.state.activeCard
    );

    if (pd && paymentDevice && pd.id !== paymentDevice.id) {
      return null;
    }

    switch (this.state.type) {
      case 'edit':
        return <CardDetails card={paymentDevice} />;
      case 'lock':
        return <LockCard card={paymentDevice} />;
      case 'pin':
        return (
          <ShowPin
            card={paymentDevice}
            locale={this.props.localization.locale}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const paymentDevice = CardService.getDefaultCard(this.props.paymentDevices);
    const { frontCard } = OverrideService.getStyles();

    if (!paymentDevice) return null;

    const { profile } = this.props;

    const { type, activeCard } = this.state;

    return (
      <div className="appContainer">
        <div
          className="container"
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          {this.props.paymentDevices.map((pd: any) => (
            <div className="card-wrapper" key={pd.id}>
              <div className="card-navigation-wrapper">
                <div style={{ position: 'relative' }}>
                  {this.getActiveCardOverlay(pd)}
                  <LPOverridable name="card.image" card={pd}>
                    <img
                      src={frontCard || require('../../../assets/card.png')}
                      className="card-page-card-image"
                      alt="card"
                    />
                  </LPOverridable>
                </div>
                <div className="card-page-card-navigation">
                  <ul className={!pd.isLive ? 'not-active' : ''}>
                    <li
                      className={
                        type === 'edit' && pd.id === activeCard ? 'active' : ' '
                      }
                      onClick={() =>
                        this.setState({ type: 'edit', activeCard: pd.id })
                      }
                    >
                      <AppIcon name="fa.pencil" style={{ fontSize: 24 }} />
                    </li>
                    <li
                      className={
                        type === 'lock' && pd.id === activeCard ? 'active' : ' '
                      }
                      onClick={() =>
                        this.setState({ type: 'lock', activeCard: pd.id })
                      }
                    >
                      <AppIcon name="fa.key" style={{ fontSize: 24 }} />
                    </li>
                    <li
                      className={
                        type === 'pin' && pd.id === activeCard ? 'active' : ' '
                      }
                      onClick={() =>
                        this.setState({ type: 'pin', activeCard: pd.id })
                      }
                    >
                      <AppIcon name="fa.ellipsis-h" style={{ fontSize: 24 }} />
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="send-load-btn-holder"
                style={{
                  opacity: pd.isLive ? 1 : 0.2,
                  pointerEvents: pd.isLive ? 'auto' : 'none'
                }}
              >
                <LPOverridable name="card.action.buttons" card={pd}>
                  {this.getLoadAccount(profile, pd)}
                  {this.getSendMoney(pd)}
                </LPOverridable>
              </div>
              <div style={{ maxWidth: 600, width: '100%' }}>
                {this.changeContent(pd)}
                {this.getActiveCardBtn(pd)}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: {
  paymentDevices: any;
  auth: { profile: any };
  localization: any;
}) => {
  return {
    paymentDevices: state.paymentDevices,
    profile: state.auth.profile,
    localization: state.localization
  };
};
export default connect(mapStateToProps)(CardPage);

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardModel from '../models/CardModel';
import { LPOverridable } from '@launchpad';
import { Trans } from 'react-i18next';
import i18n from '@launchpad/i18n';

class ShowPin extends React.PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      pin: '',
      arrayPin: '',
      text: '',
      loading: false
    };
  }

  componentDidMount() {
    this.loadPin();
  }

  async loadPin() {
    this.setState({
      loading: true
    });
    const { card } = this.props;

    const pin = await new CardModel().fetchPin(card);

    if (!pin) return null;

    this.setState({
      pin,
      arrayPin: Array.from(pin) as any,
      text: 'Hide',
      loading: false
    });
  }

  hidePin() {
    this.setState({
      pin: '',
      arrayPin: Array.from('****') as any,
      text: 'Unhide'
    });
  }

  unhidePin() {
    this.loadPin();
  }

  render() {
    const { pin, arrayPin, text } = this.state;

    let innerElement = (
      <div className="col-md-8">
        <h3>
          <Trans>Your Card PIN</Trans>
        </h3>

        <div className="col-md-12 pin-wrapper">
          <div className="pin-number-holder">
            <span>{arrayPin[0]}</span>
          </div>
          <div className="pin-number-holder">
            <span>{arrayPin[1]}</span>
          </div>
          <div className="pin-number-holder">
            <span>{arrayPin[2]}</span>
          </div>
          <div className="pin-number-holder">
            <span>{arrayPin[3]}</span>
          </div>
          {pin !== '' && pin ? (
            <h4 onClick={() => this.hidePin()} className="pl-2">
              <Trans>{text}</Trans>
            </h4>
          ) : (
            <h4 onClick={() => this.unhidePin()} className="pl-2">
              <Trans>{text}</Trans>
            </h4>
          )}
        </div>
        <LPOverridable name="showPin.page.change-pin-atm">
          <p>
            <Trans>You can change your PIN at any ATM</Trans>
          </p>
        </LPOverridable>
      </div>
    );

    if (this.state.loading) {
      innerElement = (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress className="circle-progress" />
        </div>
      );
    }

    return (
      <LPOverridable name="showPin.page.no-pin">
        <div
          className="row details-holder justify-content-center align-items-center"
          style={{ flexDirection: 'column' }}
          key={this.props.locale}
        >
          <div className="top-line" />
          {innerElement}
        </div>
      </LPOverridable>
    );
  }
}

export default ShowPin;

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppButton } from '@launchpad';
import ModalService from '@launchpad/logic/services/ModalService';
import LPOverridable from '../../components/overrides/LPOverridable';
import ProfileImage from '../../components/images/ProfileImage';
import { fullDate, date } from '../../util/DateHelper';
import { Trans } from 'react-i18next';

const Profile = ({ profile }) => {
  const profileImg = <ProfileImage profile={profile} />;
  return (
    <div className="appContainer">
      <div className="container">
        <div className="profile-container account-holder">
          <div className="owner-holder">
            <div className="profile-img-name">
              <div className="profile-image-holder">{profileImg}</div>
              <div className="owner-name">
                <p>
                  <Trans>ACCOUNT OWNER</Trans>
                </p>
                <h3>{profile.fullName}</h3>
                <p>
                  <Trans>LAST LOGIN</Trans>: {fullDate(profile.lastLogin)}
                </p>
              </div>
            </div>
            <AppButton
              onClick={() => ModalService.showModal('CHANGE_PASSWORD')}
            >
              <Trans>CHANGE PASSWORD</Trans>
            </AppButton>
          </div>

          <div className="container" style={{ paddingTop: 50 }}>
            <div className="row">
              <div className="col-md-6">
                <div className="info-wrapper">
                  <div className="profile-header">
                    <h3>
                      <Trans>Basic info</Trans>
                    </h3>
                  </div>

                  <LPOverridable name="profile.basicInfo" profile={profile}>
                    <ul>
                      <li>
                        <Trans>Title</Trans>:{' '}
                        <span>
                          <Trans>{profile.title || '-'}</Trans>
                        </span>
                      </li>
                      <li>
                        <Trans>First name</Trans>:{' '}
                        <span>{profile.firstName || '-'}</span>
                      </li>
                      <li>
                        <Trans>Middle name</Trans>:{' '}
                        <span>{profile.middleName || '-'}</span>
                      </li>
                      <li>
                        <Trans>Last name</Trans>:{' '}
                        <span>{profile.lastName || '-'}</span>
                      </li>
                      <li>
                        <Trans>Date of birth</Trans>:{' '}
                        <span>{date(profile.birthDate) || '-'}</span>
                      </li>
                    </ul>
                  </LPOverridable>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-wrapper">
                  <div className="profile-header">
                    <h3>
                      <Trans>Address</Trans>
                    </h3>
                  </div>
                  <LPOverridable name="profile.addressInfo" profile={profile}>
                    <ul>
                      <li>
                        <Trans>Street</Trans>:{' '}
                        <span>{profile.addressOne || '-'}</span>
                      </li>
                      <li>
                        <Trans>Sub Street</Trans>:{' '}
                        <span>{profile.addressTwo || '-'}</span>
                      </li>
                      <li>
                        <Trans>Postal code</Trans>:{' '}
                        <span>{profile.postCode || '-'}</span>
                      </li>
                      <li>
                        <Trans>City</Trans>: <span>{profile.city || '-'}</span>
                      </li>
                      <li>
                        <Trans>Country</Trans>:{' '}
                        <span>{profile.country.name || '-'}</span>
                      </li>
                    </ul>
                  </LPOverridable>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="info-wrapper">
                  <div className="profile-header">
                    <h3>
                      <Trans>Contact information</Trans>
                    </h3>
                  </div>
                  <LPOverridable name="profile.contactInfo" profile={profile}>
                    <ul>
                      <li>
                        <Trans>Email</Trans>:{' '}
                        <span>{profile.email || '-'}</span>
                      </li>
                      <li>
                        <Trans>Mobile No.</Trans>:{' '}
                        <span>{profile.mobile || '-'}</span>
                      </li>
                      <li>
                        <Trans>Landline</Trans>:{' '}
                        <span>{profile.landline || 'none'}</span>
                      </li>
                    </ul>
                  </LPOverridable>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-wrapper">
                  <div className="profile-header">
                    <h3>
                      <Trans>Unique Reference No</Trans>
                    </h3>
                  </div>
                  <ul>
                    <li>
                      <Trans>Reference number</Trans>:{' '}
                      <span>{profile.customerReference || '-'}</span>
                    </li>
                    <li className="red-info">
                      <LPOverridable name="profile.customerReferenceText">
                        <Trans>
                          provide this reference to your contacts who want to
                          send you money.
                        </Trans>
                      </LPOverridable>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ paddingTop: 25 }}>
          <div className="col-md-6 terms-holder">
            <div className="profile-container">
              <h5 className="privacy-text">
                <Trans>Read our Privacy Policy</Trans>{' '}
                <LPOverridable name="profile.page.privacyLink">
                  <Link to="/privacy-policy" className="terms-link">
                    <Trans>here</Trans>
                  </Link>
                </LPOverridable>
              </h5>
            </div>
          </div>
          <div className="col-md-6 terms-holder">
            <div className="profile-container">
              <h5 className="privacy-text">
                <Trans>Read our Terms & Conditions</Trans>{' '}
                <LPOverridable name="profile.page.termsLink">
                  <Link to="/terms-conditions" className="terms-link">
                    <Trans>here</Trans>
                  </Link>
                </LPOverridable>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: { auth: { profile: any } }) => {
  return {
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps)(Profile);

import ApprovedTransactionModal from '../authorisations/components/ApprovedTransactionModal';
import AuthorizationDetailsModal from '../authorisations/components/AuthorizationDetailsModal';
import InsertPasscodeModal from '../authorisations/components/InsertPasscodeModal';

export const modals = {
  AUTHORISATION_DETAILS_MODAL: AuthorizationDetailsModal,
  GENERAL_INSERT_PASSCODE: InsertPasscodeModal,
  APPROVED_TRANSACTION: ApprovedTransactionModal
};

export default modals;

import React, { useState } from 'react';
import { connect, OverrideService } from '@launchpad';
import NumberHelper from '@launchpad/util/NumberHelper';
import { Trans } from 'react-i18next';
import CustomFXRatesModal from './CustomFXRatesModal';

const ProsperBalance = ({ prosperBalance, localization }) => {
  const [modalVisible, setModalVisible] = useState(false);

  if (!prosperBalance || !prosperBalance.points) return null;

  function handleExchangeButtonPressed() {
    setModalVisible(true);
  }

  const appName = OverrideService.getStyles().APP_NAME;

  return (
    <div style={{ paddingBottom: 20 }} key={localization.locale}>
      <div>
        <h3>
          <Trans>Available {{ appName }} Points</Trans>
        </h3>
        <h1>{NumberHelper.currency(prosperBalance.points, false, true)}</h1>
      </div>
      <div
        className="points-redeem"
        onClick={() => handleExchangeButtonPressed()}
      >
        <img
          src={require('@launchpad/assets/currency-euro-dollar-exchange.svg')}
          alt="Exchange office"
          onClick={() => handleExchangeButtonPressed()}
        />
        <p className="progress-text">
          <Trans>Redeem Points</Trans>
        </p>
      </div>
      <CustomFXRatesModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    prosperBalance: state.prosperBalance.balance,
    localization: state.localization
  };
};

export default connect(mapStateToProps)(ProsperBalance);

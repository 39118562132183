import i18n from '@launchpad/i18n';
import React from 'react';

const FXRatesItem = ({ currency, text, source, imageStyle, textStyle }) => {
  let src;

  switch (currency) {
    case 'EUR':
      src = require('../../assets/countries/european-union.png');
      break;
    case 'USD':
      src = require('../../assets/countries/USA.png');
      break;
    case 'GBP':
      src = require('../../assets/countries/GBR.png');
      break;
    default:
      src = require('../../assets/countries/GBR.png');
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 5,
        zIndex: 2
      }}
    >
      <img
        src={source || src}
        alt="country"
        style={{ width: 40, height: 40, ...imageStyle }}
      />
      <p style={{ fontSize: 16, paddingLeft: 15, ...textStyle }}>
        {i18n.t(text)}
      </p>
    </div>
  );
};

export default FXRatesItem;

import { combineReducers } from 'redux';
import i18n from '../../i18n';

export function locale(state = 'en', action) {
  switch (action.type) {
    case 'LANGUAGE_CHANGE':
      return action.payload.locale;
  }

  return i18n.language;
  // return state;
}

export function dictionary(state = {}, action) {
  switch (action.type) {
    case 'DICTIONARY_LOADED':
      return { ...action.payload.dictionary };
  }
  return state;
}

export function translations(state = {}, action) {
  return state;
}

export function languages(state = [], action) {
  switch (action.type) {
    case 'CONFIG_UPDATE':
      // return [...action.payload.languages];
      return action.payload.languages || null;
  }
  return state;
}

export default combineReducers({
  locale,
  dictionary,
  translations,
  languages
});

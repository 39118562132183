import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ModalDialog from '@launchpad/components/modal/ModalDialog';
import AppButton from '@launchpad/components/crud/AppButton';
import CustomIcon from '@launchpad/components/elements/icon/CustomIcon';
import ProfileImage from '../../images/ProfileImage';
import { AppIcon, OverrideService } from '../../../index';
import DateHelper from '../../../util/DateHelper';
import {
  logoutTriggerAction,
  loginTriggerAction
} from '../../../modules/auth/AuthActions';
import ThemeSwitcher from './ThemeSwitcher';
import LanguageSwitcher from './LanguageSwitcher';
import { Trans } from 'react-i18next';

class AppTopBar extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      logoutModalVisible: false
    };
  }

  onClose() {
    this.setState({
      logoutModalVisible: false
    });
  }

  logoutUser() {
    this.props.userLoggedOut(this.props.token);
    this.onClose();
  }

  render() {
    const { profile } = this.props;

    const profileImg = <ProfileImage profile={profile} />;

    let notificatoinBadge: any = null;

    if (profile && profile.unreadNotifications) {
      notificatoinBadge = (
        <div className="notification-badge">
          <p
            className="unread-notification-number"
            style={{
              fontSize: profile.unreadNotifications.length > 2 ? 8 : 10
            }}
          >
            {profile.unreadNotifications}
          </p>
        </div>
      );
    }

    return (
      <div className="top-bar">
        <div className="top-bar-info">
          <div className="profile-image-holder">{profileImg}</div>
          <div className="name-holder">
            <h4>{profile.fullName}</h4>
            <p>
              <Trans>Last Login</Trans>:{' '}
              {DateHelper.fullDate(profile.lastLogin)}
            </p>
          </div>
          <div className="top-bar-vertical-line" />
          <Link to="/notifications" style={{ position: 'relative' }}>
            {notificatoinBadge}
            <AppIcon
              name="fa.bell"
              style={{ fontSize: 21, cursor: 'pointer' }}
            />
          </Link>
          <a
            style={{ textDecoration: 'none', paddingLeft: 20, display: 'flex' }}
            // onClick={() => this.logoutUser()}
            onClick={() =>
              this.setState({
                logoutModalVisible: true
              })
            }
          >
            <AppIcon
              name="fa.sign-out"
              style={{ fontSize: 24, cursor: 'pointer' }}
            />
          </a>
          {OverrideService.getOverrides()['hide-theme-switcher'] ? null : (
            <ThemeSwitcher />
          )}
          {OverrideService.getOverrides()['hide-language-switcher'] ? null : (
            <LanguageSwitcher />
          )}
        </div>
        <ModalDialog
          show={this.state.logoutModalVisible}
          onClose={() => this.onClose()}
        >
          <div className="fx-redeem-holder">
            <div className="exchange-confirmation-holder">
              <div
                className="close-btn"
                style={{ position: 'absolute', top: 20, right: 20 }}
              >
                <AppIcon
                  name="fa.times"
                  style={{
                    fontSize: 24,
                    cursor: 'pointer'
                  }}
                  onClick={() => this.onClose()}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                <AppIcon
                  name="fa.sign-out"
                  style={{
                    fontSize: 40,
                    cursor: 'pointer'
                  }}
                />
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    paddingTop: 20,
                    textAlign: 'center'
                  }}
                >
                  <Trans>Are you sure you want to log out of</Trans>
                  <br />
                  {OverrideService.getStyles().APP_NAME}?
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  flex: 1
                }}
              >
                <div style={{ flex: 1, marginRight: 10 }}>
                  <AppButton type="button" onClick={() => this.onClose()}>
                    <Trans>NO</Trans>
                  </AppButton>
                </div>
                <div style={{ flex: 1 }}>
                  <AppButton type="submit" onClick={() => this.logoutUser()}>
                    <Trans>Yes</Trans>
                  </AppButton>
                </div>
              </div>
            </div>
          </div>
        </ModalDialog>
      </div>
    );
  }
}
const mapStateToProps = (state: { auth: { token: any; profile: any } }) => {
  return {
    token: state.auth.token,
    profile: state.auth.profile
  };
};

const mapDispatchToProps = (dispatch: {
  (arg0: { type: string; payload: {} }): void;
  (arg0: { type: string; payload: { token: any; account: any } }): void;
}) => {
  return {
    userLoggedOut: () => {
      return dispatch(logoutTriggerAction());
    },
    userLoggedIn: (token: any, account: any) => {
      return dispatch(loginTriggerAction(token, account));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppTopBar);

import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import ModalDialog from '../../../components/modal/ModalDialog';
import CardService from '../CardService';
import ModalService from '../../../logic/services/ModalService';
import LoadingHelper from '../../../util/LoadingHelper';
import CardModel from '../models/CardModel';
import AuthService from '../../../logic/services/AuthService';
import {
  AppIcon,
  AppButton,
  LPOverridable,
  OverrideService
} from '../../../index';
import { Trans } from 'react-i18next';
import i18n from '@launchpad/i18n';

export const NAME = 'ACTIVATE_CARD';

class ActivateCardModal extends React.Component<any, any> {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);
    this.state = {
      show: true,
      digits: ['', '', '', '']
    };
  }

  onClose() {
    ModalService.hideModal('ACTIVATE_CARD');
  }

  nextFocus(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    const digits = [...this.state.digits];

    digits[index] = e.target.value;

    const regex = /^[0-9\b]+$/;

    const num = regex.test(digits[index]);

    if (e.target.value === '' || num) {
      this.setState({
        digits
      });
    } else {
      return;
    }

    if (e.target.value.length == 1) {
      $(e.target)
        .next()
        .focus();
    } else {
      $(e.target)
        .prev()
        .focus();
    }
  }

  _activateCard() {
    const digits = this.state.digits.filter(x => x !== '');

    if (digits.length < 4) return;

    const cardNumber = digits.slice(0, 4).join('');

    const card = this.props.pd;
    // Order card here
    LoadingHelper.executeAction(
      () => {
        return this._actualActivateCard(card, cardNumber);
      },
      {
        successMessage: i18n.t('Your card is activated!'),
        loadingMessage: i18n.t('Activating your card...'),
        failureMessage: i18n.t('Card activation failed!'),
        beforeClose: (status: string) => {
          if (status !== 'failure') {
            AuthService.refreshAuth();
            this.onClose();
          }
        }
      }
    );
  }

  async _actualActivateCard(card: any, cardNumber: string) {
    const cardModel = new CardModel();
    const result = await cardModel.activatePaymentDevice(card, cardNumber);

    if (result) {
      this.onClose();
      return true;
    }

    throw new Error('Card activation failed');
  }

  render() {
    const user = this.props.profile;

    if (!user) return null;

    return (
      <LPOverridable name="card.page.card-activation-modal">
        <ModalDialog show={this.state.show} onClose={this.onClose.bind(this)}>
          <div className="transaction-modal">
            <div className="close-btn">
              <AppIcon
                name="fa.times"
                style={{
                  fontSize: 24,
                  cursor: 'pointer'
                }}
                onClick={() => ModalService.hideModal()}
              />
            </div>
            <div className="inner-container">
              <h1>
                <Trans>Card Activation</Trans>
              </h1>
              <p>
                <Trans>
                  Please enter last four digits of your card pan number
                </Trans>
              </p>
              {/* <LPOverridable name="acivate.card.image"> */}
              <img
                src={
                  OverrideService.getStyles().activateCardImage ||
                  require('../../../assets/activate_card.png')
                }
                alt="activate-card"
              />
              {/* </LPOverridable> */}
              <div className="container" style={{ maxWidth: 1000 }}>
                <div className="row" style={{ justifyContent: 'center' }}>
                  <div style={{ textAlign: 'center' }}>
                    <div
                      className="input-wrapper"
                      style={{
                        flexDirection: 'row',
                        marginTop: 30,
                        marginBottom: 30
                      }}
                    >
                      <input
                        value={this.state.digits[0]}
                        type="text"
                        maxLength={1}
                        className="input-pin inputs"
                        onChange={e => this.nextFocus(e, 0)}
                        autoFocus
                      />
                      <input
                        value={this.state.digits[1]}
                        type="text"
                        maxLength={1}
                        className="input-pin inputs"
                        onChange={e => this.nextFocus(e, 1)}
                      />
                      <input
                        value={this.state.digits[2]}
                        type="text"
                        maxLength={1}
                        className="input-pin inputs"
                        onChange={e => this.nextFocus(e, 2)}
                      />
                      <input
                        value={this.state.digits[3]}
                        type="text"
                        maxLength={1}
                        className="input-pin inputs"
                        onChange={e => this.nextFocus(e, 3)}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 20
                  }}
                >
                  <AppButton onClick={() => this._activateCard()}>
                    <Trans>Activate Card</Trans>
                  </AppButton>
                </div>
              </div>
            </div>
          </div>
        </ModalDialog>
      </LPOverridable>
    );
  }
}
const mapStateToProps = (state: {
  paymentDevices: any;
  auth: { profile: any };
}) => {
  return {
    paymentDevices: state.paymentDevices,
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps)(ActivateCardModal);

import URL from 'url-parse';

import OverrideService from '@launchpad/logic/services/overrides/OverrideService';
import ConfigModel from '../logic/model/ConfigModel';

const Config = {
  env: process.env.REACT_APP_ENVIRONMENT,
  url: process.env.REACT_APP_URL || ''
};

const env =
  Config.env ||
  (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? 'dev'
    : 'live');

const publicUrl = new URL(Config.url).origin;

const common = {
  resetDB: false,
  disablePasscode: false,
  forceIntros: false,
  publicUrl,
  passcodeLength: 6,
  cardRatio: 1.56,
  physicalCardCost: 7.5,
  maxTopUpValue: 250,
  minTopUpValue: 10,
  support: {
    phone: '0113 320 1900'
  }
};

const configuration = {
  ...common,
  checkout_url: 'https://sandbox.checkout.com/api2/v2/tokens/card',
  checkout_public_key: 'pk_test_24166a6e-18f7-4405-b42a-d74aa75dc05d',
  ...Config
};

const config = {
  env,

  // Preloaded configuration
  ...configuration,
  ...OverrideService.getConfig(),

  cards: {
    cardPrice: {
      GBP: 10
    }
  },
  topUp: {
    min: 10,
    max: 200
  },

  facebook: {},
  twitter: {},
  linkedin: {},

  /**
   * Load configuration, first from db, then from API
   */
  async load() {
    try {
      // Load from network
      await this.loadFromNetwork();
    } catch (e) {
      console.log('Config Load Error', e);
    }
  },

  /**
   * Load from network
   */
  async loadFromNetwork() {
    // Get from network
    const result = await new ConfigModel().fetch();

    if (result) {
      // Merge
      this.merge(result);
    }
  },

  /**
   * Merge data with current object
   *
   * @param {Object} result
   */
  async merge(result) {
    Object.assign(this, result);
    new ConfigModel().dispatchConfigUpdate(this);
  }
};

export default config;

import TransactionInfoModal from './modals/TransactionInfoModal';
import FxInfoModal from './modals/FxInfoModal';
import MerchantInfoModal from './modals/MerchantInfoModal';

export const modals = {
  TRANSACTION_INFO: TransactionInfoModal,
  FX_INFO: FxInfoModal,
  MERCHANT_INFO: MerchantInfoModal
};

export default modals;

import BaseModel from '../../../logic/model/BaseModel';
import Api from '../../../logic/api/Api';

export default class TransactionModel extends BaseModel {
  getListUrl() {
    return 'account/transactions';
  }

  // async getItems(period, type, limit = null) {
  //   let url = null;
  //   switch(type) {
  //     case 'all':
  //       url = 'account/transactions';
  //     break;
  //     case 'country':
  //       url = 'account/transactions/countries';
  //     break;
  //     case 'merchant':
  //       url = 'account/transactions/merchants';
  //     break;
  //     case 'category':
  //       url = 'account/transactions/categories';
  //     break;
  //   }

  //   let result = await Api.call(url, {
  //     limit
  //   });
  //   return result.data.items;
  // }

  async getMerchantDetails(id) {
    const url = 'account/transaction/merchant/details';

    const result = await Api.call(url, {
      id
    });

    if (result.success) {
      return result.data;
    }

    return null;
  }

  async getDownloadUrl(fileType, filter) {
    const url = 'transactions/export';
    // switch (type) {
    //   case "stataments":
    //     url = "transactions/export";
    //     break;
    //   case "account":
    //     url = "account/export";
    //     break;
    // }

    const result = await Api.call(url, {
      type: fileType || 'pdf',
      // account: accountId,
      // categoryId: filter?.categoryId,
      startDate: filter?.startDate,
      endDate: filter?.endDate,
      cardToken: filter?.cardToken || null,
      currency: filter?.currency || null
    });

    if (result.success) {
      // let afterThirdSlashUrl = result.data.download
      //   .split("/")
      //   .slice(3)
      //   .join("/");
      const afterThirdSlashUrl = result.data.download;

      return afterThirdSlashUrl;
    }

    return null;
  }
}

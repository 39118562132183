import i18next from '../../i18n';
import Api from '../api/Api';
import appStore from '../store/AppStore';

export default class LocalizationService {
  static getLocale() {
    if (appStore) {
      const state = appStore.getState();

      if (state && state.localization.locale) {
        return state.localization.locale;
      }
    }

    return 'en';
  }

  static getDefaultLocale() {
    return 'en';
  }

  static setLocale(locale) {
    // Store locale in local storage
    window.localStorage.setItem('LOCALE', locale);

    // Change i18n locale
    i18next.changeLanguage(locale);

    // Update state
    appStore.dispatch({
      type: 'LANGUAGE_CHANGE',
      payload: { locale }
    });
  }

  static translate(key) {
    const dictionary = LocalizationService.getDictionary();
    const locale = LocalizationService.getLocale();

    if (dictionary[key] && dictionary[key][locale]) {
      return dictionary[key][locale];
    }

    return key;
  }

  static getDictionary() {
    const state = appStore ? appStore.getState() : null;

    if (!state) return {};

    if (state.localization.dictionary) {
      return state.localization.dictionary;
    }

    return {};
  }

  async loadDictionary() {
    const response = await Api.call('translation/dictionary');

    for (const locale in response.data) {
      i18next.addResourceBundle(locale, 'translation', response.data[locale]);
    }

    return;
    if (response.success) {
      appStore.dispatch({
        type: 'DICTIONARY_LOADED',
        payload: {
          dictionary: response.data
        }
      });
    }
  }
}

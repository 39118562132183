import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import balanceReducer from './balance.reducer';

function tttttttt(state: any = null, action: any) {
  switch (action.type) {
    case 'MEMBER_AUTH_REFRESH':
      return 'boban';
    default:
  }
  return state;
}

const reducers = {
  t: tttttttt,
  prosperBalance: balanceReducer
};

export default reducers;

import React from 'react';
import { Trans } from 'react-i18next';
import {
  AppButton,
  AppIcon,
  InputField,
  ModalDialog
} from '@launchpad/components';
import ModalService from '../../../logic/services/ModalService';
import Api from '../../../logic/api/Api';
import i18n from '../../../i18n';

// Define the unique name for this modal
export const NAME = 'GENERAL_INSERT_PASSCODE';

export default class InsertPasscodeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      otp: '',
      attempts: 3,
      isLoading: false
    };
  }

  // Handle form submission
  async handleSubmit() {
    try {
      this.setState({ isLoading: true });
      // Call the API to verify the passcode
      const result = await Api.call(this.props.url || `otp/verify/code`, {
        input: this.state.otp,
        hash: this.props.hash
      });

      // Check the result of passcode verification
      if (result.success && result.data.nextState.name !== 'INPUT_WRONG') {
        // Passcode is correct, close the modal and invoke the callback
        this.onClose();
        this.props.callback(result.data);
      } else {
        // Passcode is incorrect
        if (this.state.attempts === 1) {
          // No more attempts left, close the modal
          this.setState({ isLoading: false });
          this.onClose();
        } else {
          // Decrease the remaining attempts and update the state
          this.setState(prevState => ({
            ...prevState,
            attempts: prevState.attempts - 1,
            isLoading: false
          }));
        }
      }
    } catch (error) {
      // Handle unexpected errors
      console.log('UNEXPECTED ERROR:', error);
      this.setState({ isLoading: false });
    }
  }

  // Close the modal
  onClose() {
    ModalService.hideModal(NAME);
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    return (
      <div>
        <ModalDialog
          show
          onClose={() => this.onClose()}
          style={{
            zIndex: 999999999999
          }}
          {...this.props}
        >
          <div className="lp-modal">
            <AppIcon
              name="icon_message"
              style={{ fontSize: 70, color: '#D44E27' }}
            />
            {/* Passcode entry form */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <h3
                style={{
                  marginTop: 20,
                  textAlign: 'center',
                  fontSize: '1.2rem',
                  marginBottom: 10
                }}
              >
                <Trans>Insert a passcode</Trans>
              </h3>
              <span style={{ textAlign: 'center', margin: '20px 0' }}>
                {this.props.description ? (
                  <Trans>{this.props.description}</Trans>
                ) : (
                  <Trans>
                    We’ve sent you a passcode. Please enter it below
                  </Trans>
                )}
              </span>
              {/* Input field for passcode */}
              <InputField
                className="input-group"
                materialProps={{
                  fullWidth: true,
                  variant: 'standard'
                }}
                label={i18n.t('Insert a passcode')}
                name="otp"
                type="text"
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.otp}
                onInputText={(value, name) => this.setState({ otp: value })}
              />
              {this.state.attempts !== 3 && (
                <div>
                  <span style={{ fontSize: 12, color: 'red' }}>
                    <Trans>
                      Authentication attempted failed. Remaining attempts{' '}
                    </Trans>
                    {this.state.attempts}.
                  </span>
                </div>
              )}
            </div>
            <div
              style={{
                marginTop: 40,
                marginBottom: 20,
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <AppButton
                type="button"
                style={{ minWidth: 150 }}
                onClick={() => this.onClose()}
              >
                <Trans>Cancel</Trans>
              </AppButton>
              <div style={{ width: 20 }} />
              <AppButton
                isLoading={this.state.isLoading}
                style={{ minWidth: 150 }}
                onClick={() => this.handleSubmit()}
              >
                <Trans>Submit</Trans>
              </AppButton>
            </div>
          </div>
        </ModalDialog>
      </div>
    );
  }
}

import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Api from '../../logic/api/Api';
import FXRatesItem from './FXRatesItem';
import AppButton from '../../components/crud/AppButton';
import { getCurrencySymbol } from '../../util/CurrencyHelper';
import Auth from '../../logic/services/AuthService';
import { useSelector } from 'react-redux';
import i18n from '@launchpad/i18n';
import { Trans } from 'react-i18next';

const ExchangeConfirmationDialog = ({
  amount,
  baseCurrency,
  convertedCurrency,
  onHide
}) => {
  const [loading, setLoading] = useState(true);
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationSuccess, setConfirmationSuccess] = useState(false);
  const [quoteReference, setQuoteReference] = useState('');
  const paymentDevice = useSelector(state => state.paymentDevices[0]);
  const [shouldClose, setShouldClose] = useState(false);

  async function fetchConvertedAmount() {
    try {
      setLoading(true);
      const result = await Api.call('fx/quote/create', {
        fromCurrency: baseCurrency.currency,
        toCurrency: convertedCurrency.currency,
        amount
      });
      setLoading(false);
      if (!result.success || !result.data) {
        setConfirmationSuccess(false);
        return setConfirmationMessage(
          result.message || 'Something went wrong.'
        );
      }
      setQuoteReference(result.data.quoteReference);
      return setConvertedAmount(result.data.exchangeAmount);
    } catch (error) {
      setLoading(false);
      setConfirmationSuccess(false);
      return setConfirmationMessage(error.message || 'Something went wrong.');
    }
  }

  useEffect(() => {
    // Call api to get converted value
    fetchConvertedAmount();
  }, []);

  async function handleSubmitButton() {
    try {
      setLoading(true);
      const result = await Api.call('fx/quote/execute', {
        paymentDeviceId: paymentDevice.id,
        quoteReference
      });
      setLoading(false);
      if (!result.success) {
        setConfirmationSuccess(false);
        return setConfirmationMessage(
          result.message || 'Something went wrong.'
        );
      } else {
        setConfirmationSuccess(true);
        Auth.refreshAuth();
        setShouldClose(true);
        const message =
          result.data?.message || 'You have successfully exchanged money.';
        return setConfirmationMessage(message);
      }
    } catch (error) {
      setLoading(false);
      setConfirmationSuccess(false);
      return setConfirmationMessage(error.message || 'Something went wrong.');
    }
  }

  if (confirmationMessage) {
    return (
      <div className="exchange-confirmation-message-container">
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column-reverse',
            alignItems: 'center',
            paddingBottom: 10
          }}
        >
          {/* Show icon based on result.success */}
          {confirmationSuccess ? (
            <img
              src={require('../../assets/check-circle-1.svg')}
              alt="Success"
              style={{ width: 50, height: 50 }}
            />
          ) : (
            <img
              src={require('../../assets/alert-circle.svg')}
              alt="Success"
              style={{ width: 50, height: 50 }}
            />
          )}
        </div>
        <div
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <p style={{ fontSize: 20, textAlign: 'center' }}>
            {i18n.t(confirmationMessage)}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            flexDirection: 'column-reverse',
            width: '100%'
          }}
        >
          <AppButton type="submit" onClick={() => onHide(shouldClose)}>
            <Trans>OK</Trans>
          </AppButton>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress className="circle-progress" size={20} />
      </div>
    );
  }

  return (
    <div className="exchange-confirmation-holder">
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div style={{ justifyContent: 'space-evenly', flex: 1 }}>
          <p
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              paddingBottom: 20
            }}
          >
            <Trans>Are you sure you want to exchange</Trans>
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <FXRatesItem
              {...baseCurrency}
              text={
                getCurrencySymbol(baseCurrency.currency) +
                parseFloat(amount).toFixed(2)
              }
              textStyle={{ fontWeight: 'bold' }}
            />
            <p
              style={{
                fontSize: 20,
                paddingHorizontal: 20
              }}
            >
              <Trans>To</Trans>
            </p>
            <FXRatesItem
              {...convertedCurrency}
              text={
                getCurrencySymbol(convertedCurrency.currency) +
                parseFloat(convertedAmount).toFixed(2)
              }
              textStyle={{ fontWeight: 'bold' }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <div style={{ flex: 1, marginRight: 10 }}>
          <AppButton type="submit" onClick={() => onHide()}>
            <Trans>NO</Trans>
          </AppButton>
        </div>
        <div style={{ flex: 1 }}>
          <AppButton type="submit" onClick={() => handleSubmitButton()}>
            <Trans>Yes</Trans>
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default ExchangeConfirmationDialog;

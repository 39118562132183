import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppImage from '@launchpad/components/images/AppImage';
import { LocalizationService } from '@launchpad/logic/localization';
import i18n from '@launchpad/i18n';

class LanguageSwitcher extends Component {
  _changeLanguage(language) {
    LocalizationService.setLocale(language.locale);
  }

  render() {
    const { languages } = this.props.localization;
    const currentLocale = this.props.localization.locale || 'en';

    const currentLanguage =
      languages && languages.find(x => x.locale === currentLocale);

    if (!currentLanguage) return null;

    const otherLanguages = [];

    languages.forEach(language => {
      if (language.locale !== currentLocale) {
        otherLanguages.push(
          <li className="nav-item" key={`language${language.name}`}>
            <a
              href="javascript:void(0)"
              onClick={() => this._changeLanguage(language)}
            >
              {i18n.t(language.name)}{' '}
              <AppImage src={language.flag} className="flag-image" />
            </a>
          </li>
        );
      }
    });

    return (
      <div className="dropdown lang-dropdown">
        <button
          type="button"
          className="dropdown-toggle"
          data-toggle="dropdown"
          style={{ background: 'transparent', border: 'none', outline: 'none' }}
        >
          <a href="#">
            {this.props.showCurrentLanguageName && currentLanguage.name}
            <AppImage
              src={currentLanguage.flag}
              className="flag-image"
              style={{
                marginLeft: this.props.showCurrentLanguageName ? 10 : 0
              }}
            />
          </a>
        </button>
        <ul className="dropdown-menu">{otherLanguages}</ul>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    profile: state.auth.profile,
    localization: state.localization
  };
};

export default connect(mapStateToProps)(LanguageSwitcher);

import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormComponent from './FormComponent';
import AppButton from './AppButton';
import ToastHelper from '../../util/ToastHelper';
import i18n from '@launchpad/i18n';

export default class FormSubmitWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textMessage: ''
    };
    // Initial state
    this.formHandler = props.formHandler;

    this.formHandler.on('submitSuccess', data => {
      const customMessage = this.props.getModalMessage(data);
      this.setState({
        textMessage:
          customMessage || data.message || 'The form is successfully saved.'
      });
    });

    this.formHandler.on('submitError', data => {
      const customMessage = this.props.getModalMessage(data);
      this.setState(
        {
          textMessage:
            customMessage ||
            data.message ||
            'Oops, something is wrong, you can not finish this action.'
        },
        () => {
          ToastHelper.show(this.state.textMessage);
        }
      );
    });

    this.formHandler.on('inputChange', data => {
      this.setState(
        {
          textMessage: ''
        },
        () => {
          ToastHelper.close();
        }
      );
    });
  }

  _getButtonContent() {
    if (this.formHandler.state.isLoading || this.state.redirect) {
      return (
        <CircularProgress
          className={`circle-progress ${this.props.invertedBtn ? 'light' : ''}`}
          size={30}
        />
      );
    }
    return (
      <span style={{ textTransform: 'uppercase' }}>
        {i18n.t(this.props.buttonText)}
      </span>
    );
  }

  render() {
    const buttonContent = this._getButtonContent();

    const { formHandler } = this;

    return (
      <div>
        <FormComponent formHandler={formHandler}>
          {this.props.children}

          {/* <div className='formRow' style={{ marginTop: 20, display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}> */}
          <div
            className={`submitButtonWrapper ${this.props.changeSubmitButtonWrapper}`}
          >
            <div
              className={`form-button-container ${this.props.buttonContainer}`}
            >
              {!this.props.hideSubmit && (
                <AppButton inverted={this.props.invertedBtn} type="submit">
                  {buttonContent}
                </AppButton>
              )}
            </div>
          </div>
        </FormComponent>
      </div>
    );
  }
}

FormSubmitWrapper.defaultProps = {
  buttonText: '',
  getModalMessage: () => null,
  click: () => null,
  buttonContainer: '',
  changeSubmitButtonWrapper: ''
};

/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-unused-state */
import React from 'react';
import { Trans } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalDialog from '../../../components/modal/ModalDialog';
import ModalService from '../../../logic/services/ModalService';
import { AppIcon } from '../../../index';
import TransactionModel from '../models/TransactionModel';
import { fullDate } from '../../../util/DateHelper';

const InfoItem = props => {
  return (
    <div className="d-flex flex-column flex-md-row align-items-center justify-content-center w-100 pb-1">
      <div className="w-50 text-center text-md-left text-wrap text-break">
        <span className="text-center text-md-left">
          <Trans>{props.title}</Trans>
        </span>
      </div>
      <div className="w-100 text-center text-md-right text-wrap text-break">
        {props.link || (
          <span className="text-center text-md-right">
            <Trans>{props.content}</Trans>
          </span>
        )}
      </div>
    </div>
  );
};

export default class MerchantInfoModal extends React.PureComponent {
  /**
   * Constructor
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      merchantInfo: null,
      loading: true
    };
  }

  componentDidMount() {
    this._getMerchantDetails();
  }

  // eslint-disable-next-line consistent-return
  async _getMerchantDetails() {
    const { id } = this.props;
    try {
      const merchantDetails = await new TransactionModel().getMerchantDetails(
        id
      );

      this.setState(
        {
          merchantInfo: merchantDetails
        },
        () => {
          this.setState({
            loading: false
          });
        }
      );
    } catch (err) {
      console.log(err);
      this.setState({
        loading: false
      });
    }
  }

  render() {
    const { merchantInfo } = this.state;

    return (
      <ModalDialog id="MERCHANT_INFO" show>
        <div className="transaction-modal">
          <div className="close-btn">
            <AppIcon
              name="fa.times"
              style={{
                fontSize: 24,
                cursor: 'pointer'
              }}
              onClick={() => ModalService.hideModal('MERCHANT_INFO')}
            />
          </div>
          {this.state.loading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ flex: 1 }}
            >
              <CircularProgress className="circle-progress" size={20} />
            </div>
          ) : (
            <div
              className="d-flex flex-column align-items-center justify-content-start w-100 mt-10"
              style={{
                minHeight: 450,
                flex: 1
              }}
            >
              {!merchantInfo || merchantInfo.length === 0 ? (
                <span>
                  <Trans>
                    Sorry, we currently have no information about the merchant.
                  </Trans>
                </span>
              ) : (
                <div className="d-flex flex-column align-items-center justify-content-center w-100">
                  <img
                    src={
                      merchantInfo.logo
                        ? merchantInfo.logo[0].url
                        : require('../../../assets/no-image.png')
                    }
                    alt="merchant-logo"
                    style={{
                      width: 80,
                      height: 'auto',
                      marginBottom: 20
                    }}
                  />

                  <InfoItem title="Name:" content={merchantInfo.name || '-'} />
                  <InfoItem
                    title="Address:"
                    content={merchantInfo.address || '-'}
                  />
                  <InfoItem
                    title="MCC:"
                    content={merchantInfo.merchant_category_code || '-'}
                  />
                  <h4 className="my-2">Contact</h4>
                  <InfoItem
                    title="Email:"
                    content={merchantInfo.contact?.email || '-'}
                  />
                  <InfoItem
                    title="Phone:"
                    content={merchantInfo.contact?.phone || '-'}
                  />
                  <InfoItem
                    title="Website:"
                    link={
                      merchantInfo.contact?.websiteUrl ? (
                        <a
                          target="_blank"
                          href={merchantInfo.contact?.websiteUrl}
                        >
                          {merchantInfo.contact?.websiteUrl}
                        </a>
                      ) : (
                        '-'
                      )
                    }
                  />

                  <h4 className="my-2">Receipt</h4>
                  <InfoItem
                    title="Url:"
                    link={
                      merchantInfo.receipt?.url ? (
                        <a target="_blank" href={merchantInfo.receipt?.url}>
                          {merchantInfo.receipt?.url}
                        </a>
                      ) : (
                        '-'
                      )
                    }
                  />
                  <InfoItem
                    title="Expires On:"
                    content={fullDate(merchantInfo.receipt?.expiresOn) || '-'}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </ModalDialog>
    );
  }
}

const INITIAL_STATE = {
  balance: {}
};

const balanceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'MEMBER_AUTH_LOADED':
      return {
        ...state,
        balance: action.payload.prosperBalance
      };
    default:
      return state;
  }
};

export default balanceReducer;
